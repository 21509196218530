import React, {useContext, useEffect, useState} from "react";
import {Button, Card, CardBody, Link, Skeleton, Spacer} from "@nextui-org/react";
import DealCard from "~/components/DealComponent/DealCard";
import Carousel from "~/components/DealComponent/Carousel";
import {useDataQuery} from "@/hooks/useDataQuery";
import {useParams} from "react-router-dom";
import DealDetails from "@/components/DealComponent/DealDetails";
import {UserContext} from "@/providers/UserProvider";
import {Helmet} from "react-helmet";

export default function DealPage() {
    const params = useParams();
    const { data, isLoading, isError, refetch } = useDataQuery({url: `/api/deals/${params.id}`});
    const {userLoggedIn, logIn, logOut, setUserLoggedIn, user} = useContext(UserContext);
    const [daysLeft, setDaysLeft] = useState(0);
    const [expirationMessage, setExpirationMessage] = useState('');
    const [expirationBannerColor, setExpirationBannerColor] = useState('');

    useEffect(() => {
        if (!isLoading && data.expiration) {
            const expiredIn = Math.floor((Date.parse(data.expiration) - Date.now()) / (1000 * 60 * 60 * 24)) + 1
            setDaysLeft(expiredIn)
            switch (expiredIn) {
                case 0:
                    setExpirationMessage('Le Deal expire aujourd\'hui!');
                    setExpirationBannerColor('bg-orange-400');
                    break;
                case 1:
                    setExpirationMessage('Le Deal expire demain!');
                    setExpirationBannerColor('bg-blue-400');
                    break;
                case 2:
                    setExpirationMessage('Le Deal expire après-demain!');
                    setExpirationBannerColor('bg-yellow-400');
                    break;
                default:
                    if (expiredIn < 0) {
                        setExpirationMessage('Le Deal a expiré!');
                        setExpirationBannerColor('bg-red-400');
                    } else if (expiredIn > 2) {
                        setExpirationMessage(`Il reste ${expiredIn} jours avant l'expiration du Deal!`);
                        setExpirationBannerColor('bg-green-400');
                    }
            }
        }
    }, [isLoading]);

    return (
        <div className='flex flex-col items-center justify-center w-full'>
            {isLoading && <Card className="w-[200px] space-y-5 p-4" radius="lg">
                <Skeleton className="rounded-lg">
                    <div className="h-24 rounded-lg bg-default-300"></div>
                </Skeleton>
                <div className="space-y-3">
                    <Skeleton className="w-3/5 rounded-lg">
                        <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                    </Skeleton>
                    <Skeleton className="w-4/5 rounded-lg">
                        <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
                    </Skeleton>
                    <Skeleton className="w-2/5 rounded-lg">
                        <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
                    </Skeleton>
                </div>
            </Card>}
            {!isLoading && !isError && <>
                <Helmet>
                    <title>{data.title} - tunisiandeals.com</title>
                    <meta name="description" content={data.description}/>
                    <meta name="keywords"
                          content="deals, tunisia, shopping, online, discounts, bonnes affaires, offres, tunisie"/>
                    <meta property="og:title" content={data.title}/>
                    <meta property="og:image"
                          content={data.images[0] && new URL(data.images[0], window.location.origin).href}/>
                    <meta property="og:url" content={window.location.href}/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:description" content={data.description}/>
                    <rel link="canonical" href={window.location.href}/>

                </Helmet>
                <Spacer x={6}/>
                {data.expiration && <Card isBlurred className={`border-none ${expirationBannerColor} max-w-[800px] w-full`}>
                    <CardBody className="items-center">
                        <p className={"font-bold"}>{expirationMessage} </p>
                    </CardBody>
                </Card>}
                <Spacer x={6} />
                <DealCard deal={data} refetch={refetch} forceFullWidth={true}/>

                <Spacer x={6}/>
                <Carousel selectedFiles={data.images} kind={'img'}/>
                <Spacer x={6}/>
                <DealDetails deal={data} user={user}/>
                {user && (data.user_id === user.id) && <Button
                    className={"font-semibold"}
                    as={Link} color="primary"
                    variant="bordered"
                    href={`/deal/new/${data.id}`}
                    style={{
                        position: 'fixed',
                        bottom: '20px',
                        right: '20px',
                        zIndex: 1000
                    }}
                >
                    Mettre à jour ce Deal
                </Button>}
            </>}
        </div>
    )
}