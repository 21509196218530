import React, {useMemo, useState} from 'react'
import {Helmet} from "react-helmet";
import {Button, Card, CardBody, CardFooter, CardHeader, Image, Input, Link, Spacer} from "@nextui-org/react";
import {tdlogo} from "~/assets";
import Toast from "~/components/Toast";

export default function Verify() {


    const showError = (message) => {
        setToast({ message: message, type: 'error', visible: true });
        setTimeout(() => setToast({ ...toast, visible: false }), 3000);
    };

    const showSuccess = (message) => {
        setToast({ message: message, type: 'success', visible: true });
        setTimeout(() => setToast({ ...toast, visible: false }), 3000);
    };
    const [email, setEmail] = useState("");
    const [toast, setToast] = useState({message: '', type: '', visible: false});
    const validateEmail = (value) => value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i);
    const isInvalid = useMemo(() => {
        if (email === "") return false;

        return validateEmail(email) ? false : true;
    }, [email]);

    const handleSubmit = async () => {
        try {
            const response = await fetch('/api/services/resend_confirmation', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({email: email})
            });

            const data = await response.json();
            if (data.error === 'User already confirmed') {
                showError('Ton compte est déjà confirmé. Connecte toi maintenant.')
                setTimeout(() => window.location.href = '/signin', 3000);
            } else if (data.error === 'Error sending confirmation instructions') {
                showError('Erreur lors de l\'envoi du mail de confirmation. réessaye plus tard.');
            } else {
                showSuccess('Mail de confirmation envoyé avec succès. Vérifie ta boite mail.')
            }

        } catch(error) {
            showError('Erreur lors de l\'envoi du mail de confirmation. réessaye plus tard.');
        }
    }


    return (
        <div className='flex flex-col items-center justify-center w-full'>
            <Helmet>
                <title>Verify Email - Tunisiandeals.com</title>
                <meta name="description" content="Mail verification page"/>
                <meta name="keywords"
                      content="deals, tunisia, shopping, online, discounts, bonnes affaires, offres, tunisie"/>
            </Helmet>
            <Spacer x={6}/>
            <Card isBlurred className="border-none dark:bg-default-100/50 max-w-[800px] w-full">
                <CardHeader className='flex-col justify-center font-semibold'>
                    <Image src={tdlogo} width={300} height={300} alt="Tunisian Deals Logo"/>
                    <span>Verifie ton adresse Mail pour être un memebre actif de la communauté</span>
                </CardHeader>
                <CardBody className="items-center">
                    <span>Pas de Mail? Donne ton adresse puis reessaye.</span>
                    <Input type="email" variant={'underlined'} label="Email" placeholder="Ton adresse mail" id={'email'}
                           isClearable isRequired onChange={(e) => setEmail(e.target.value)}
                           isInvalid={isInvalid} color={isInvalid ? 'danger' : 'success'}
                           errorMessage={isInvalid && "L'email n'est pas valide"}
                    />
                    <p className="text-center text-small">
                        Pas de compte?{" "}
                        <Link size="sm" href={'/register'}>
                            Créer un compte
                        </Link>
                    </p>
                </CardBody>
                <CardFooter className='flex justify-center'>
                    <Button className="font-semibold" onClick={handleSubmit}>
                        Renvoyer le mail
                    </Button>
                </CardFooter>
            </Card>
            {toast.visible && <Toast message={toast.message} type={toast.type}/>}
        </div>
    )
}