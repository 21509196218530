import React, {Fragment, useContext, useEffect, useMemo, useState} from "react";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Image,
    Input,
    Link,
    Skeleton,
    Spacer,
    Tooltip
} from "@nextui-org/react";
import {UserContext} from "@/providers/UserProvider";
import {tdlogo} from "@/assets";
import {Helmet} from "react-helmet";
import Toast from "~/components/Toast";

export default function Signin() {
    const {userLoggedIn, logIn, logOut, isLoadingUser} = useContext(UserContext);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [disableResetPw, setDisableResetPw] = useState(false);

    useEffect(() => {
        if (userLoggedIn) {
            window.location.href = '/';
        }
        if (!isInvalid) {
            setDisableResetPw(false);
        }
    }, [email]);

    const [toast, setToast] = useState({ message: '', type: '', visible: false });

    const showError = (message) => {
        setToast({ message: message, type: 'error', visible: true });
        setTimeout(() => setToast({ ...toast, visible: false }), 3000);
    };

    const showSuccess = (message) => {
        setToast({ message: message, type: 'success', visible: true });
        setTimeout(() => setToast({ ...toast, visible: false }), 3000);
    };


    const handleResetPw = () => {
        setDisableResetPw(true);
        fetch('/api/services/reset_password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({email})
        }).then((res) => res.json()).then((data) => {
            if (data.status === 'ok') {
                showSuccess('Les instructions pour réinitialiser votre mot de passe ont été envoyées à ton adresse mail');
            } else {
                showError('Une erreur est survenue, verfiie ton adresse mail et réessaye.');
            }

        })
    }

    const validateEmail = (value) => value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i);
    const isInvalid = useMemo(() => {
        if (email === "") return false;

        return validateEmail(email) ? false : true;
    }, [email]);
    const handleSubmit = (e) => {
        e.preventDefault();
        logIn(email, password);
    }
    return (
        <div className='flex flex-col items-center justify-center w-full'>
            <Helmet>
                <title>Sign-in - Tunisiandeals.com</title>
                <rel link="canonical" href={window.location.href}/>
                <meta name="description" content="sign in page"/>
                <meta name="keywords"
                      content="deals, tunisia, shopping, online, discounts, bonnes affaires, offres, tunisie"/>
            </Helmet>
            {(isLoadingUser || userLoggedIn) && <Fragment key={1}>
            <Card className="w-[800px] space-y-5 p-4" radius="lg">
                    <Skeleton className="rounded-lg" key={`skeleton1`}>
                        <div className="h-24 rounded-lg bg-default-300"></div>
                    </Skeleton>
                    <div className="space-y-3">
                        <Skeleton className="w-3/5 rounded-lg" key={`skeleton2`}>
                            <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                        </Skeleton>
                        <Skeleton className="w-4/5 rounded-lg" key={`skeleton3`}>
                            <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
                        </Skeleton>
                        <Skeleton className="w-2/5 rounded-lg" key={`skeleton4`}>
                            <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
                        </Skeleton>
                    </div>
                </Card>
                <Spacer x={6} />
            </Fragment>}
            {!userLoggedIn && <>
                <Spacer x={6} />
                <Card isBlurred className="border-none dark:bg-default-100/50 max-w-[800px] w-full">
                    <CardHeader className='flex-col justify-center font-semibold'>
                        <Image src={tdlogo} width={300} height={300} alt="Tunisian Deals Logo"/>
                        <span>Connecte toi à ton compte</span>
                    </CardHeader>
                    <CardBody className="items-center">
                        <Input type="email" variant={'underlined'} label="Adresse Mail" id={'email'}
                               isClearable isRequired onChange={(e) => setEmail(e.target.value)}
                               isInvalid={isInvalid} color={isInvalid ? 'danger' : 'success'}
                               onClear={() => setDisableResetPw(true)}
                               errorMessage={isInvalid && "L'email n'est pas valide"}
                        />
                        <Input type="password" variant={'underlined'} label="Mot de passe"
                               id={'password'} isClearable isRequired onChange={(e) => setPassword(e.target.value)}/>

                        <Tooltip content={'Entre ton adresse mail pour recevoir les instructions'}>
                            <p className="text-center text-small">
                                Mot de passe oublié?{" "}
                                <Link size="sm" onClick={handleResetPw} isDisabled={disableResetPw}>
                                    Réinitialiser le mot de passe
                                </Link>
                            </p>
                        </Tooltip>
                        <p className="text-center text-small">
                            Pas de compte?{" "}
                            <Link size="sm" href={'/register'}>
                                Créer un compte
                            </Link>
                        </p>
                    </CardBody>
                    <CardFooter className='flex justify-center'>
                        <Button className="font-semibold" onClick={handleSubmit}>
                            Connexion
                        </Button>
                    </CardFooter>
                </Card>
            </>}
            {toast.visible && <Toast message={toast.message} type={toast.type} />}
        </div>
    )
}