import React from "react";
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    useDisclosure,
    ScrollShadow, Divider
} from "@nextui-org/react";
import {useDataQuery} from "@/hooks/useDataQuery";

export default function CategoriesModal({setCategory}) {

    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const { data, isLoading, isError, refetch } = useDataQuery({url: '/api/categories'});

    let items = []
    if (!isLoading) items = data.map((category) => {
        return {name: category.name, description: category.description, id: category.id}
    })

    return (
        <>
            <Button className={"font-bold"} variant={'light'} onPress={onOpen}>Les Catégories</Button>
            <Divider orientation={'horizontal'} />
            <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Les Catégories des Deals</ModalHeader>
                            <ModalBody>
                                <ScrollShadow hideScrollBar className="flex-col w-[300px] h-[400px]">
                                    <div className={"flex flex-col"}>
                                        {items.map((item, index) => (
                                            <Button key={index}
                                                    variant="light"
                                                    onPress={() => {
                                                        setCategory({
                                                            id: item.id, name: item.name
                                                        });
                                                        onClose();
                                                    }}>
                                                #{item.name}
                                            </Button>
                                        ))}
                                    </div>
                                </ScrollShadow>
                            </ModalBody>
                            <ModalFooter className={"justify-start"}>
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Fermer
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}