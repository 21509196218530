import React, {useEffect, useState} from 'react';
import {
    Link,
    User,
    Divider,
    Chip,
    ButtonGroup,
    Button,
} from '@nextui-org/react';
import CommentInput from "@/components/DealComponent/CommentInput";
import {HeartIcon} from "@/components/DealComponent/HeartIcon";
import {useDataQuery} from "@/hooks/useDataQuery";

export default function Comment({ comment, deal, refetchComments, user }) {
    const [showAnswer, setShowAnswer] = useState(false);
    const [showCommentAnswers, setShowCommentAnswers] = useState(false);
    const [liked, setLiked] = useState(false);
    const { data, isLoading, isError } = useDataQuery({url: `/api/services/current_user`});

    useEffect(() => {
        const likes = comment.comments_likes;
        const userLike = likes.find(like => like.user_id === data.id);
        if (userLike) {
            setLiked(true);
        }
    }, []);

    const handleLike = async () => {
        setLiked((v) => !v);
        try {
            await addLike();
            refetchComments();
        } catch (e) {
            console.error(e);
        }
    }
    async function addLike() {
        const response = await fetch(`/api/deals/${deal.id}/comments/${comment.id}/comments_likes`, {
            method: 'POST',
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    }

  return (
      <div className={'sm:flex w-full items-start justify-start grid-cols-12 gap-4 p-3'}>
          <User
              name={comment.user.name}
              description={(
                  <Link href="https://twitter.com/jrgarciadev" size="sm" isExternal>
                      @{comment.user.name}
                  </Link>
              )}
              avatarProps={{
                  src: `https://ui-avatars.com/api/?name=${comment.user.name}`
              }}
          />
          <Divider orientation="vertical" />
          <div className={'w-full'}>
              <div className={'flex justify-between'}>
                  <span>{comment.content}</span>
                  <div className={'flex'}>
                      {comment.comments_likes.length > 0 && <Chip variant={'faded'} size="sm">{comment.comments_likes.length}</Chip>}
                      <Button
                          isIconOnly
                          className="text-default-900/60 data-[hover]:bg-foreground/10 -translate-y-2 translate-x-2"
                          radius="full"
                          variant="light"
                          onPress={handleLike}
                      >
                          <HeartIcon
                              className={liked ? "[&>path]:stroke-transparent" : ""}
                              fill={liked ? "currentColor" : "none"}
                          />
                      </Button>
                  </div>
              </div>
              <Divider orientation="horizontal"/>
              <div className={'flex items-center justify-end p-3'}>
                  {comment.parent_id === null && <>
                      <Button radius="full"
                              className="bg-gradient-to-tr from-pink-500 to-yellow-500 text-white shadow-lg"
                              onClick={() => setShowAnswer((showAnswer) => !showAnswer)}>
                          Répondre
                      </Button>
                      {comment.replies.length > 0 && <Button radius="full"
                               className="bg-gradient-to-tr from-pink-500 to-yellow-500 text-white shadow-lg"
                               onClick={() => setShowCommentAnswers((showCommentAnswers) => !showCommentAnswers)}>
                          {comment.replies.length} Réponses
                      </Button>}
                  </>}
              </div>
              {showAnswer && !user.error &&
                  <CommentInput deal={deal} refetchComments={refetchComments} isReply={true}
                                parentCommentID={comment.id} user={user}/>}
              {comment.replies && showCommentAnswers && comment.replies.map(reply => (
                  <Comment key={reply.id} comment={reply} deal={deal} refetchComments={refetchComments}/>
              ))}
          </div>
      </div>
  );
}