import React, {useState} from "react";
import {Input, Select, SelectItem, Spacer} from "@nextui-org/react";

export default function Delivery({type, progress, setProgress, dealUrl, setDealUrl, deliveryCost, setDeliveryCost,
                                     location, setLocation, dealId=null}) {
    const isOnline = type === 'online';
    const isLocal = type === 'local';
    const isBoth = type === 'both';
    const governoratesList =["Ariana",  "Béja",  "Ben Arous",  "Bizerte",  "Gabès",  "Gafsa",  "Jendouba",  "Kairouan",  "Kasserine",  "Kébili",  "Le Kef",  "Mahdia",  "La Manouba",  "Médenine",  "Monastir",  "Nabeul",  "Sfax",  "Sidi Bouzid",  "Siliana",  "Sousse",  "Tataouine", "Tozeur", "Tunis", "Zaghouan"]

    const handleDeliveryCostChange = (e) => {
        setDeliveryCost(e.target.value);
    };
    const handleDealUrlChange = (e) => {
        if (e.target.value === ' ') return;
        if (e.target.value.length >= 1 && dealUrl.length === 0 && (location === '' || location === null)) setProgress(progress + 1);
        setDealUrl(e.target.value);
        if (e.target.value.length === 0) setProgress(progress - 1);
    };
    const handleLocationChange = (e) => {
        const value = e.target.value;
        if (value === ' ') return;
        if (isLocal && value !== '' && location === '' && dealUrl === '') setProgress(progress + 1);
        setLocation(value);
        if (isLocal && value === '') setProgress(progress - 1);
    };

    return (
        <div className='flex flex-col items-center justify-center w-full'>
            {(isOnline || isBoth) &&
            <>
                <Input
                    type="text"
                    label="Lien du deal"
                    placeholder="Ajouter un lien vers le deal..."
                    labelPlacement="outside"
                    value={dealUrl}
                    onChange={handleDealUrlChange}
                    onClear={() => {
                        setDealUrl('');
                        setProgress(progress - 1);
                    }}
                    isClearable
                    isRequired
                />
                <Spacer x={6}/>
                <Input
                    type="number"
                    label="Frais de port"
                    placeholder="0.00"
                    labelPlacement="outside"
                    value={deliveryCost}
                    onChange={handleDeliveryCostChange}
                    onClear={() => setDeliveryCost(0)}
                    startContent={
                        <div className="pointer-events-none flex items-center">
                            <span className="text-default-400 text-small">DT</span>
                        </div>
                    }/>
                <Spacer x={6}/>
            </>
            }
            {(isLocal || isBoth) &&
                <Select
                    label="Lieu"
                    labelPlacement={'outside'}
                    placeholder="Où se trouve le deal ?"
                    selectionMode="multiple"
                    className="max-w-xs"
                    isRequired={isLocal}
                    value={location}
                    onChange={handleLocationChange}
                    selectedKeys={location === null ? [] : location.split(',')}
                    scrollShadowProps={{
                        isEnabled: false
                    }}
                >
                    {governoratesList.map((gov, index) => (
                        <SelectItem key={index} value={gov}>
                            {gov}
                        </SelectItem>
                    ))}
                </Select>
            }
        </div>
    );
}