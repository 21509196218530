import React from 'react';

export default function Welcome() {
    return (
        <div className="flex flex-col items-center justify-center w-full">
            <span className={"text-5xl font-bold text-left p-3"}>
                Partageons plus que des bons plans
            </span>
            <span className={"text-xl p-3"}>
                Chaque jour, des offres publiées, évaluées et commentées par une communauté de membres passionnés.
            </span>
        </div>
    )
}