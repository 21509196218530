import React, {Fragment, useContext, useEffect, useMemo, useState} from "react";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Checkbox,
    Image,
    Input,
    Link,
    Skeleton,
    Spacer, Tooltip
} from "@nextui-org/react";
import {UserContext} from "@/providers/UserProvider";
import {tdlogo} from "@/assets";
import {Helmet} from "react-helmet";

export default function Signup() {

    const {userLoggedIn, logIn, logOut, setUserLoggedIn, isLoadingUser} = useContext(UserContext);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [acceptMarketing, setAcceptMarketing] = useState(false);

    useEffect(() => {
        if (userLoggedIn) {
            window.location.href = '/';
        }
    }, []);

    const validateEmail = (value) => value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i);
    const isInvalid = useMemo(() => {
        if (email === "") return false;

        return validateEmail(email) ? false : true;
    }, [email]);
    const handleSubmit = async () => {
    if (password !== confirmPassword) {
        alert("Passwords do not match");
        return;
    }

    const userData = { name: name, email: email, user_name: username, password: password, accept_marketing: acceptMarketing };

    try {
        const response = await fetch('/signup', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({user: userData})
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        } else {
            setUserLoggedIn(true);
            window.location.href = '/Verify';
        }

        const data = await response.json();
    } catch (error) {
        console.error(error);
    }
};

    return (
        <div className='flex flex-col items-center justify-center w-full'>
            <Helmet>
                <title>Signup - tunisiandeals.com</title>
                <meta name="description" content="signup page"/>
                <rel link="canonical" href={window.location.href}/>
                <meta name="keywords"
                      content="deals, tunisia, shopping, online, discounts, bonnes affaires, offres, tunisie"/>
            </Helmet>
            {isLoadingUser && userLoggedIn && <Fragment key={1}>
            <Card className="w-[800px] space-y-5 p-4" radius="lg">
                    <Skeleton className="rounded-lg" key={`skeleton1`}>
                        <div className="h-24 rounded-lg bg-default-300"></div>
                    </Skeleton>
                    <div className="space-y-3">
                        <Skeleton className="w-3/5 rounded-lg" key={`skeleton2`}>
                            <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                        </Skeleton>
                        <Skeleton className="w-4/5 rounded-lg" key={`skeleton3`}>
                            <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
                        </Skeleton>
                        <Skeleton className="w-2/5 rounded-lg" key={`skeleton4`}>
                            <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
                        </Skeleton>
                    </div>
                </Card>
                <Spacer x={6} />
            </Fragment>}
            {!userLoggedIn && <>
                <Spacer x={6} />
                <Card isBlurred className="border-none dark:bg-default-100/50 max-w-[800px] w-full">
                    <CardHeader className='flex-col justify-center'>
                        <Image src={tdlogo} width={300} height={300} alt="Tunisian Deals Logo"/>
                        <span>Créer un compte</span>
                    </CardHeader>
                    <CardBody className="items-center">
                        <Input type="text" variant={'underlined'} label="Nom et prénom" placeholder="Pour t'appeler par ton nom"
                               isClearable isRequired onChange={e => setName(e.target.value)}/>
                        <Input type="email" variant={'underlined'} label="Adresse mail" placeholder="Pour te contacter"
                               isClearable isRequired onChange={e => setEmail(e.target.value)}
                               isInvalid={isInvalid} color={isInvalid ? 'danger' : 'success'}
                               errorMessage={isInvalid && "L'email n'est pas valide"}
                        />
                        <Input type="text" variant={'underlined'} label="Nom d'utilisateur" placeholder="Pour garder ton identité"
                               isClearable isRequired onChange={e => setUsername(e.target.value)}/>
                        <Input type="password" variant={'underlined'} label="Mot de passe" placeholder="Pour sécuriser ton compte"
                               isClearable isRequired onChange={e => setPassword(e.target.value)}/>
                        <Input type="password" variant={'underlined'} label="Confirmer le mot de passe"
                               placeholder="Pour être sûr de ce que tu as tapé"
                               isClearable isRequired onChange={e => setConfirmPassword(e.target.value)}/>
                        <Spacer x={6} />
                        <Tooltip content="Coche cette case si tu souhaite recevoir des emails marketing de notre part">
                            <Checkbox defaultSelected color="success" onChange={() => setAcceptMarketing(!acceptMarketing)}>Recevoir par Mails nos news</Checkbox>
                        </Tooltip>
                        <Spacer x={6} />
                        <p className="text-center text-small">
                            Déja membre de la communauté?{" "}
                            <Link size="sm" href={'/signin'}>
                                s'identifier
                            </Link>
                        </p>
                    </CardBody>
                    <CardFooter className='flex flex-col justify-center'>
                        <Button className="font-semibold" onClick={handleSubmit}>Créer</Button>
                        <Spacer x={6} />
                        <h6 className="text-center text-small">En cliquant sur "Créer", tu acceptes nos <Link href={'/terms-of-use'}>Conditions d'utilisation</Link> et notre <Link href={'/privacy-policy'}>Politique de confidentialité</Link></h6>
                    </CardFooter>
                </Card>
            </>}
        </div>
    )
}