import React from 'react';
import {Card, CardHeader, ScrollShadow, Spacer} from "@nextui-org/react";

export default function TermsOfUse() {
    return (
        <div className='flex flex-col items-center justify-center w-full'>
            <Spacer x={6}/>
            <Card isBlurred className="flex border-none dark:bg-default-100/50 max-w-[800px] w-full p-8">
                <CardHeader className={"text-large font-bold justify-center"}>Conditions d'utilisation de Tunisiandeals</CardHeader>
                <ScrollShadow size={100} className="w-full h-[600px]">
                    <p className={"font-semibold"}>Introduction</p>
                    <p>Bienvenue sur Tunisiandeals! En utilisant notre site web, vous acceptez les présentes Conditions
                        d'utilisation. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser notre site.
                    </p>
                    <Spacer x={6}/>
                    <p className={"font-semibold"}>Utilisation du Site</p>
                    <ul>
                        <li><div className={"text-medium font-medium text-blue-500"}>Âge Minimum:</div> Vous devez avoir au moins 12 ans pour utiliser notre site. Si
                            vous avez moins de 12 ans, vous pouvez utiliser le site uniquement sous la supervision d'un
                            parent ou tuteur légal.
                        </li>
                        <li><div className={"text-medium font-medium text-blue-500"}>Inscription:</div> Certaines fonctionnalités de Tunisiandeals peuvent nécessiter
                            une inscription. Vous devez fournir des informations exactes et complètes lors de votre
                            inscription et mettre à jour ces informations en cas de changement.
                        </li>
                        <li><div className={"text-medium font-medium text-blue-500"}>Compte:</div> Vous êtes responsable de la sécurité de votre compte et des
                            activités qui s'y déroulent. Veuillez informer immédiatement Tunisiandeals en cas
                            d'utilisation non autorisée de votre compte.
                        </li>
                    </ul>
                    <Spacer x={6}/>
                    <h2 className={"font-semibold"}>Contenu de L'utilisateur</h2>
                    <ul>
                        <li><div className={"text-medium font-medium text-blue-500"}>Propriété du Contenu:</div> Vous conservez tous les droits sur le contenu que
                            vous téléchargez, partagez ou publiez sur Tunisiandeals. En publiant du contenu, vous
                            accordez à Tunisiandeals une licence mondiale, non exclusive et gratuite pour utiliser,
                            distribuer, reproduire, modifier, adapter, publier et afficher ce contenu dans le cadre de
                            l'exploitation du site.
                        </li>
                        <li><div className={"text-medium font-medium text-blue-500"}>Responsabilité du Contenu:</div> Vous êtes seul responsable du contenu que vous
                            publiez. Vous ne devez pas publier de contenu illégal, offensant, diffamatoire, ou qui viole
                            les droits d'autrui.
                        </li>
                        <li><div className={"text-medium font-medium text-blue-500"}>Modération:</div> Tunisiandeals se réserve le droit de supprimer tout contenu qui
                            enfreint ces Conditions d'utilisation ou qui est jugé inapproprié par nos modérateurs.
                        </li>
                    </ul>
                    <Spacer x={6}/>
                    <h2 className={"font-semibold"}>Comportement de l'Utilisateur</h2>
                    <ul>
                        <li><div className={"text-medium font-medium text-blue-500"}>Respect des Lois:</div> Vous devez respecter toutes les lois et réglementations
                            locales, nationales et internationales applicables lors de l'utilisation de Tunisiandeals.
                        </li>
                        <li><div className={"text-medium font-medium text-blue-500"}>Utilisation Interdite:</div> Vous ne devez pas utiliser le site pour:
                            <ul>
                                <li>Harceler, menacer ou abuser d'autres utilisateurs.</li>
                                <li>Publier ou transmettre des spams, des virus ou tout autre code malveillant.</li>
                                <li>Collecter des informations personnelles sur d'autres utilisateurs sans leur
                                    consentement.
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <Spacer x={6}/>
                    <h2 className={"font-semibold"}>Limitation de Responsabilité</h2>
                    <p className={"text-red-400"}>Tunisiandeals n'est pas responsable des dommages directs, indirects, accidentels, consécutifs ou
                        punitifs résultant de votre utilisation du site. Nous ne garantissons pas que le site sera
                        disponible en tout temps ou exempt d'erreurs.</p>
                    <Spacer x={6}/>
                    <h2 className={"font-semibold"}>Modifications des Conditions</h2>
                    <p>Tunisiandeals se réserve le droit de modifier ces Conditions d'utilisation à tout moment. Les
                        modifications entreront en vigueur dès leur publication sur le site. Votre utilisation continue
                        de Tunisiandeals après la publication des modifications constitue votre acceptation des
                        nouvelles conditions.</p>
                    <Spacer x={6}/>
                    <h2 className={"font-semibold"}>Contact</h2>
                    <p>Pour toute question concernant ces Conditions d'utilisation, veuillez nous contacter à l'adresse
                        suivante: <a href="mailto:businessandadministration@it-patrol.tech">businessandadministration@it-patrol.tech</a>.</p>

                </ScrollShadow>
            </Card>
        </div>
    )
}