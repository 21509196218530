import React, { useState } from 'react';
import Toast from "@/components/Toast";
import {Button, Card, CardBody, CardHeader, Image, Input, Spacer, Textarea} from "@nextui-org/react";
import {tdlogo} from "@/assets";
import {useDataQuery} from "@/hooks/useDataQuery";
import {Helmet} from "react-helmet";


export default function Feedback() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [toast, setToast] = useState({ message: '', type: '', visible: false });
    const { data, isLoading, isError } = useDataQuery({url: `/api/services/current_user`});


    const showError = (message) => {
        setToast({ message: message, type: 'error', visible: true });
        setTimeout(() => setToast({ ...toast, visible: false }), 3000);
    };
    const showSuccess = (message) => {
        setToast({ message: message, type: 'success', visible: true });
        setTimeout(() => setToast({ ...toast, visible: false }), 3000);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const feedback = {
            name: data.name,
            email: data.email,
            message: message,
        };

        fetch('/api/feedbacks', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ feedback }),
        })
            .then(response => response.json())
            .then(data => {
                showSuccess('Feedback envoyé avec succès. Redirection en cours...')
                setTimeout(() => {
                    window.location.href = '/';
                }, 3000);
            })
            .catch((error) => {
                showSuccess('Feedback envoyé avec succès. Redirection en cours...')
                setTimeout(() => {
                    window.location.href = '/';
                }, 3000);
            });
    };

    return (
        <div className='flex flex-col items-center justify-center w-full'>
            <Helmet>
                <title>Feedback - Your App Name</title>
                <meta name="description" content="Feedback page"/>
                <rel link="canonical" href={window.location.href}/>
                {/* other meta tags */}
            </Helmet>
            <Spacer x={6}/>
            <Card className={"border-none bg-background/60 dark:bg-default-100/50 max-w-[800px] w-full"}>
                <CardHeader className='flex-col justify-center'>
                    <Image src={tdlogo} width={300} height={300} alt="Tunisian Deals Logo"/>
                    <span>Envoyer un Feedback, un critique ou signaler une erreur</span>
                </CardHeader>
                <CardBody>
                    <Spacer x={6} />
                    <Textarea value={message} onChange={e => setMessage(e.target.value)}
                              placeholder="Message, critique ou erreur" label="Message" variant={'underlined'}
                              isClearable isRequired onClear={() => setMessage('')}/>
                    <Spacer x={6} />
                    <Button type="submit" color={'primary'} onClick={handleSubmit}>Envoyer</Button>
                </CardBody>
                {toast.visible && <Toast message={toast.message} type={toast.type}/>}
            </Card>
        </div>
    );
}
