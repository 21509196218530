import React, {useContext} from "react";
import {Button, Card, CardFooter, CardHeader, Image, Link} from "@nextui-org/react";
import {UserContext} from "@/providers/UserProvider";

export default function EmptyState({message}) {
    const {user, isLoadingUser} = useContext(UserContext);

    return (
        <div className='flex flex-col items-center justify-center w-full'>
            <p className="text-lg font-semibold p-8">{message}</p>
            <Card className="hidden sm:flex w-[600px] h-[600px]">
                <CardHeader className="absolute z-10 top-1 flex-col !items-start">
                    <p className="text-tiny text-white/60 uppercase font-bold">Tunisian Deals</p>
                    <h4 className="text-white font-medium text-large">Partageons plus</h4>
                </CardHeader>
                <Image
                    removeWrapper
                    alt="Card background"
                    className="z-0 w-full h-full object-cover"
                    src="https://nextui.org/images/card-example-2.jpeg"
                    width={300}
                    height={200}
                />
                <CardFooter className={"absolute z-10 bottom-1"}>
                    <Button className={"text-white text-large"} as={Link} href={user && user.error ? '/signin' : '/deal/new'} color="primary" variant="contained">Ajouter un Deal</Button>
                </CardFooter>
            </Card>

            <Card className="lg:hidden md:hidden">
                <CardHeader className="absolute z-10 top-1 flex-col !items-start">
                    <p className="text-tiny text-white/60 uppercase font-bold">Tunisian Deals</p>
                    <h4 className="text-white font-medium text-large">Partageons plus</h4>
                </CardHeader>
                <Image
                    removeWrapper
                    alt="Card background"
                    className="z-0 w-full h-full object-cover"
                    src="https://nextui.org/images/card-example-2.jpeg"
                    width={300}
                    height={200}
                />
                <CardFooter className={"absolute z-10 bottom-1"}>
                    <Button className={"text-white text-large"} as={Link} href={ user && user.error ? '/signin' : '/deal/new'} color="primary" variant="contained">Ajouter un Deal</Button>
                </CardFooter>
            </Card>
        </div>
    );
}