import React from 'react';
import {Input, Button, Skeleton, Spacer, Card, CardBody, CardHeader} from "@nextui-org/react";
import ReactFileReader from "react-file-reader";
import { FiCamera } from "react-icons/fi";
import Carousel from "~/components/DealComponent/Carousel";
import {CameraIcon} from "@/components/DealComponent/CameraIcon";

export default function ImageUpload({selectedFiles, setSelectedFiles, progress, setProgress}) {
    const handleFileChange = (files) => {
        let urls  = files.base64.map(file => file);
        if (selectedFiles.length > 0 && selectedFiles[0].includes('placeholder')) setProgress(progress + 1);
        if (selectedFiles.length === 0 && urls.length > 0) setProgress(progress + 1);
        if (selectedFiles.length > 0 && !selectedFiles[0].includes('placeholder')) urls = selectedFiles.concat(urls);
        setSelectedFiles(urls.reverse());
        document.querySelector('.react-file-reader-input').value = null;
    }
    const handleClick = () => {
        document.querySelector('.react-file-reader-input').click();
    }
    return (
        <Card className={'flex w-full max-w-[800px] p-4'}>
            <CardHeader>Télécharger les images du Deal<span className={'text-red-500'}>*</span></CardHeader>
            <CardBody className={'flex flex-col items-center justify-center w-full'}>
                <Spacer x={6}/>
                <Carousel setSelectedFiles={setSelectedFiles} selectedFiles={selectedFiles} kind={'img'}
                          progress={progress} setProgress={setProgress}
                />
                <Spacer x={6}/>
                <ReactFileReader
                    fileTypes={[".png", ".jpg", ".jpeg"]}
                    base64={true}
                    handleFiles={handleFileChange}
                    multipleFiles={true}
                >
                    <Button onClick={handleClick} className={"react-file-reader-button"} color="success" endContent={<CameraIcon/>}>Télécharger une ou des images</Button>
                </ReactFileReader>
            </CardBody>
        </Card>
    );
}