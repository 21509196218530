import React, {createContext, useEffect, useState} from 'react';
import {useDataQuery} from "@/hooks/useDataQuery";
import Toast from "@/components/Toast";

// Create a context
export const UserContext = createContext(undefined, undefined);


// Create a provider component
export default function UserProvider({ children }) {
  const [userLoggedIn, setUserLoggedIn] = useState(localStorage.getItem('userLoggedIn') === 'true');
  const { data: user, isLoading: isLoadingUser, isError: isErrorUser, refetch: refetchUser } = useDataQuery({url: `/api/services/current_user`});
  const [toast, setToast] = useState({ message: '', type: '', visible: false });

  const showError = (message) => {
    setToast({ message: message, type: 'error', visible: true });
    setTimeout(() => setToast({ ...toast, visible: false }), 1000);
  };

  const showSuccess = (message) => {
    setToast({ message: message, type: 'success', visible: true });
    setTimeout(() => setToast({ ...toast, visible: false }), 1000);
  };

  useEffect(() => {
    localStorage.setItem('userLoggedIn', userLoggedIn);
  }, [userLoggedIn])
  // Function to log in the user
  const logIn = async (email, password) => {
    fetch('/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        user: {
          email: email,
          password: password
        }
      }),
    })
        .then(response => response.json())
        .then(data => {
          if (data.error === 'You have to confirm your email address before continuing.') {
            showError('Il faut confirmer ton adresse email avant de continuer. Redirection en cours...');
            setTimeout(() => {
              window.location.href = '/verify';
            }, 1000);
          } else if (data.error) {
            showError('Erreur lors de la connexion. Verifie tes informations et réessaye.');
          } else {
            setUserLoggedIn(true);
            showSuccess('Connexion réussie. Redirection en cours...')
            setTimeout(() => {
              window.location.href = '/';
            }, 1000);
          }
        })
        .catch((error) => {
            showError('Erreur lors de la connexion. Verifie tes informations et réessaye.');
        });
  };

  // Function to log out the user
  const logOut = () => {
    fetch('/logout', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
        .then(response => response.json())
        .then(data => {
          // Set the userLoggedIn state to true
          window.location.href = '/';
        })
        .catch((error) => {
          showError('Erreur lors de la déconnexion. Réessaye plus tard.')
        });
    setUserLoggedIn(false);
  };

  const value = {
    userLoggedIn: userLoggedIn,
    logIn,
    logOut,
    setUserLoggedIn,
    user,
    isLoadingUser,
    refetchUser
  };

  return <UserContext.Provider value={value}>
    {children}
    {toast.visible && <Toast message={toast.message} type={toast.type} />}
  </UserContext.Provider>;
}