import React, {useContext, useEffect, useState} from "react";
import {Card, CardBody, Image, Button, Slider, Chip, Avatar, Spacer, CardFooter} from "@nextui-org/react";
import {HeartIcon} from "./HeartIcon";
import {cover} from "../../assets";
import {UserContext} from "@/providers/UserProvider";

export default function DealCard({deal, refetch, forceFullWidth = false}) {
    const [liked, setLiked] = useState(false);
    const [saving, setSaving] = useState(100 - Math.round(deal.price / deal.observed_price * 100));
    const [degrees, setDegrees] = useState(deal.temperature);
    const [plusDisabled, setPlusDisabled] = useState(false);
    const [minusDisabled, setMinusDisabled] = useState(false);
    const {user, isLaodingUser} = useContext(UserContext);
    const [daysLeft, setDaysLeft] = useState(Math.floor((Date.parse(deal.expiration) - Date.now()) / (1000 * 60 * 60 * 24)) + 1);
    const [temperatureColor, setTemperatureColor] = useState('bg-gradient-to-br from-indigo-500 to-pink-500');

    useEffect(() => {
        if (user && !user.error) {
            deal.temperature_votes.find(vote => vote.user_id === user.id) && deal.temperature_votes.find(vote => vote.user_id === user.id).value === -1 ? setMinusDisabled(true) : setMinusDisabled(false);
            deal.temperature_votes.find(vote => vote.user_id === user.id) && deal.temperature_votes.find(vote => vote.user_id === user.id).value === 1 ? setPlusDisabled(true) : setPlusDisabled(false);
        }
        setDegrees(deal.temperature);
        if (deal.temperature >= 10) {
            setTemperatureColor('bg-gradient-to-br from-amber-500 to-red-500');
        } else if (deal.temperature < 0) {
            setTemperatureColor('bg-gradient-to-br from-blue-500 to-white-500');
        }
    })

    const handleLike = async() => {
        setLiked((v) => !v);
        await addLike();
    }

    async function addLike() {
        const response = await fetch(`/api/deals/${deal.id}/deals_likes`, {
            method: 'POST',
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    }

    const handleVote = async(value) => {
        setDegrees(degrees + value);
        await addVote(value);
    }

    async function addVote(vote_value) {
        const response = await fetch(`/api/deals/${deal.id}/vote?vote_value=${vote_value}`, {
            method: 'POST',
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        if (response.ok) refetch();

        const data = await response.json();
        return data;
    }

    useEffect(() => {
        if (!isLaodingUser && user) {
            const likes = deal.deals_likes;
            const userLike = likes.find(like => like.user_id === user.id);
            if (userLike) {
                setLiked(true);
            }
        }
    }, [isLaodingUser]);

    return (
        <>
            { !isLaodingUser && <>
                <Card
                    isBlurred
                    className={`${!forceFullWidth ? 'hidden sm:flex' : ''} border-none bg-background/60 dark:bg-default-100/50 max-w-[800px] w-full`}
                    shadow="lg"
                    isPressable={!forceFullWidth}
                    onClick={() => window.location.href = "/deal/" + deal.id}
                    isDisabled={daysLeft < 0}
                >
                    <CardBody>
                        <div className="grid grid-cols-6 md:grid-cols-12 gap-6 md:gap-4 items-center justify-center">
                            <div className="relative col-span-6 md:col-span-4">
                                <Image
                                    alt="Deal cover"
                                    className="object-cover w-inline-size"
                                    height={150}
                                    shadow="md"
                                    src={deal.images[0]}
                                    width="100%"
                                    isZoomed
                                />
                            </div>

                            <div className="flex flex-col col-span-6 md:col-span-8">
                                <div className="flex justify-between items-start">
                                    <div className="flex flex-col gap-0">
                                        <h3 className="font-semibold text-foreground/90">{deal.category.name}</h3>
                                        <p className="text-medium text-foreground/80">{deal.merchant}</p>
                                        <div className={"flex"}>
                                            {deal.price > 0 && <>
                                                <h2>{deal.price} DT</h2>
                                                <Spacer y={2}/>
                                                {deal.observed_price > 0 && <h2 className={"line-through"}>{deal.observed_price} DT</h2>}
                                                <Spacer y={2}/>
                                                {saving > 0 && <Chip color="danger">{saving}%</Chip>}
                                            </>}
                                            {deal.price === 0 && <Chip color="success">GRATUIT</Chip>}
                                        </div>
                                        <h1 className="text-large font-medium mt-2">{deal.title}</h1>
                                        <span className="text-small text-foreground/80 overflow-ellipsis line-clamp-2">

                                </span>
                                    </div>
                                    <Button
                                        isIconOnly
                                        className="text-default-900/60 data-[hover]:bg-foreground/10 -translate-y-2 translate-x-2"
                                        radius="full"
                                        variant="light"
                                        onPress={() => {
                                            if (user.error) {
                                                window.location.href = "/signin";
                                                return;
                                            }
                                            handleLike();
                                        }}
                                    >
                                        <HeartIcon
                                            className={liked ? "[&>path]:stroke-transparent" : ""}
                                            fill={liked ? "currentColor" : "none"}
                                        />
                                    </Button>
                                </div>

                                <div className="flex flex-col mt-3 gap-1">
                                    <div className="flex justify-between">
                                        {deal.start && <p className="text-small text-foreground/50">À partir
                                            du {deal.start.split('T')[0]}</p>}
                                        {deal.expiration &&
                                            <p className="text-small text-foreground/50">Jusqu'au: {deal.expiration.split('T')[0]}</p>}
                                    </div>
                                </div>

                                <Spacer x={6} />

                                <div className="flex w-full items-end justify-end">
                                    <Chip
                                        variant="flat"
                                        avatar={
                                            <Avatar
                                                isBordered
                                                radius={'sm'}
                                                name={deal.user.user_name}
                                                src={`https://ui-avatars.com/api/?name=${deal.user.user_name}`}
                                            />
                                        }
                                    >
                                        {deal.user.user_name}
                                    </Chip>
                                    <Spacer x={6} />
                                    <Chip
                                        variant="shadow"
                                        classNames={{
                                            base: `bg-gradient-to-br ${temperatureColor} border-small border-white/50`,
                                            content: "drop-shadow shadow-black text-white",
                                        }}
                                    >
                                        <Button
                                            isIconOnly
                                            className="data-[hover]:bg-foreground/10"
                                            radius="full"
                                            variant="light"
                                            onPress={() => {
                                                if (user.error) {
                                                    window.location.href = "/signin";
                                                    return;
                                                }
                                                handleVote(-1);
                                            }}
                                            isDisabled={minusDisabled || daysLeft <= 0}
                                        >
                                            -
                                        </Button>
                                        {degrees}°
                                        <Button
                                            isIconOnly
                                            className="data-[hover]:bg-foreground/10"
                                            radius="full"
                                            variant="light"
                                            onPress={() => {
                                                if (user.error) {
                                                    window.location.href = "/signin";
                                                    return;
                                                }
                                                handleVote(1);
                                            }}
                                            isDisabled={plusDisabled || daysLeft <= 0}
                                        >
                                            +
                                        </Button>
                                    </Chip>
                                </div>
                                <Spacer x={6} />
                                {deal.link && <div className="flex w-full items-end justify-end">
                                    <Button radius="full"
                                            className="bg-gradient-to-tr from-green-500 to-green-100 text-white shadow-lg"
                                            onClick={() => window.open(deal.link, "_blank")}
                                    >
                                        Voir le deal
                                    </Button>
                                </div>}
                            </div>
                        </div>
                    </CardBody>
                </Card>

                <Card
                    className={`${forceFullWidth ? 'hidden' : 'lg:hidden md:hidden'} border-none bg-background/60 h-min-[300px] dark:bg-default-100/50 max-w-[800px] w-full items-center`}
                    shadow="sm" key={deal.id} isPressable onPress={() => window.location.href = "/deal/" + deal.id}
                    isFooterBlurred
                    isDisabled={daysLeft < 0}
                >
                    <div style={{position: 'relative', minHeight: '300px', alignContent: 'center'}}>
                        <Button
                            isIconOnly
                            className="text-default-900/60 bg-white data-[hover]:bg-foreground/10 -translate-y-2 translate-x-2"
                            radius="full"
                            variant="light"
                            style={{position: 'absolute', right: '10px', zIndex: 15, top: '10%', transform: 'translateY(-50%)'}}
                            onPress={() => {
                                if (user.error) {
                                    window.location.href = "/signin";
                                    return;
                                }
                                handleLike();
                            }}
                        >
                            <HeartIcon
                                className={liked ? "[&>path]:stroke-transparent" : ""}
                                fill={liked ? "currentColor" : "none"}
                            />
                        </Button>
                        <Image
                            shadow="sm"
                            radius="lg"
                            width={"100%"}
                            alt={deal.title}
                            className="object-cover justify-center"
                            src={deal.images[0]}
                            height={"100%"}
                            isZoomed
                        />
                    </div>
                    <CardFooter
                        className="flex-col text-small before:bg-white/10 border-white/20 border-1 overflow-hidden py-1 absolute before:rounded-xl rounded-large bottom-1 w-[calc(100%_-_8px)] shadow-small ml-1 z-10">
                        <b className={"overflow-ellipsis line-clamp-1"}>{deal.title}</b>
                        <div className={"flex"}>
                            {deal.price > 0 && <>
                                <h2>{deal.price} DT</h2>
                                <Spacer y={2}/>
                                {deal.observed_price > 0 && <h2 className={"line-through"}>{deal.observed_price} DT</h2>}
                                <Spacer y={2}/>
                                {saving > 0 && <Chip color="danger">{saving}%</Chip>}
                            </>}
                            {deal.price === 0 && <Chip color="success">GRATUIT</Chip>}
                        </div>
                        <div className="flex justify-around">
                            <Chip
                                variant="shadow"
                                classNames={{
                                    base: `bg-gradient-to-br ${temperatureColor} border-small border-white/50`,
                                    content: "drop-shadow shadow-black text-white",
                                }}
                            >
                                <Button
                                    isIconOnly
                                    className="data-[hover]:bg-foreground/10"
                                    radius="full"
                                    variant="light"
                                    onPress={() => {
                                        if (user.error) {
                                            window.location.href = "/signin";
                                            return;
                                        }
                                        handleVote(-1);
                                    }}
                                    isDisabled={minusDisabled}
                                >
                                    -
                                </Button>
                                {degrees}°
                                <Button
                                    isIconOnly
                                    className="data-[hover]:bg-foreground/10"
                                    radius="full"
                                    variant="light"
                                    onPress={() => {
                                        if (user.error) {
                                            window.location.href = "/signin";
                                            return;
                                        }
                                        handleVote(1);
                                    }}
                                    isDisabled={plusDisabled}
                                >
                                    +
                                </Button>
                            </Chip>
                        </div>

                    </CardFooter>
                </Card>

            </>}
        </>
    );
}
