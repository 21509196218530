import React from "react";
import {
    Button,
    Dropdown, DropdownItem,
    DropdownMenu,
    DropdownTrigger,
    Navbar,
    NavbarContent
} from "@nextui-org/react";
import {useDataQuery} from "@/hooks/useDataQuery";

export default function CategoriesNavBar({setCategory}) {

    const { data, isLoading, isError, refetch } = useDataQuery({url: '/api/categories'});

    let items = []
    if (!isLoading) items = data.map((category) => {
        return {name: category.name, description: category.description, id: category.id}
    })

    return (
        <>
            <Navbar isBordered className={'flex items-center justify-center categories-nav'} position={'static'}>
                <NavbarContent justify={'center'}>
                    <Dropdown>
                        <DropdownTrigger>
                            <Button
                                variant="light"
                                className={"font-bold"}
                            >
                                LES CATEGORIES
                            </Button>
                        </DropdownTrigger>
                        <DropdownMenu
                            aria-label="Action event example"
                        >
                            {items.map((item, index) => (
                                <DropdownItem key={index} onClick={() => setCategory({
                                    id: item.id, name: item.name
                                })}
                                              description={item.description}
                                >
                                    {item.name}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                </NavbarContent>
            </Navbar>
        </>
    );
}