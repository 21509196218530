import React from 'react';

export default function  Toast ({ message, type }) {
    const toastStyle = {
        position: 'fixed',
        bottom: '0%',
        left: '50%',
        transform: 'translate(-50%)',
        padding: '10px',
        color: 'white',
        animation: 'slide 3s ease',
        backgroundColor: type === 'success' ? 'green' : 'red',
    };

  return (
    <div style={toastStyle}>
      {message}
    </div>
  );
};