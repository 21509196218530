import React, {Fragment, useContext, useEffect, useState} from 'react';
import NavBar from "~/components/NavBar";
import DealCard from "~/components/DealComponent/DealCard";
import {Card, Skeleton, Spacer, Pagination, CardBody, Button, Link} from "@nextui-org/react";
import {useDataQuery} from "@/hooks/useDataQuery";
import CategoriesNavBar from "@/components/Categories/CategoriesNavBar";
import Welcome from "@/components/Welcome";
import {UserContext} from "@/providers/UserProvider";
import CategoriesModal from "@/components/Categories/CategoriesModal";
import EmptyState from "@/components/EmptyState";
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";
import Toast from "~/components/Toast";
import SideDeals from "@/components/DealComponent/SideDeals";

export default function DealsPage({category, setCategory}) {
    const [page, setPage] = useState(1);
    const { data, isLoading, isError, refetch } = useDataQuery({url: `/api/deals?page=${page}&category_id=${category ? category.id : null}`});
    const { data: numberOfPages, isLoading: isLoadingPages } = useDataQuery({url: `/api/services/deals_pages`});
    const {userLoggedIn, logIn, logOut, setUserLoggedIn, user} = useContext(UserContext);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const completed = queryParams.get('completed');
    const [toast, setToast] = useState({ message: '', type: '', visible: false });
    const [newDealHref, setNewDealHref] = useState('');


    const showError = (message) => {
        setToast({ message: message, type: 'error', visible: true });
        setTimeout(() => setToast({ ...toast, visible: false }), 3000);
    };

    const showSuccess = (message) => {
        setToast({ message: message, type: 'success', visible: true });
        setTimeout(() => setToast({ ...toast, visible: false }), 3000);
    };

    useEffect(() => {
        if (completed === 'true') {
            showSuccess('Ton compte a été verifié avec succès.');
        }
        if (user && user.verified && userLoggedIn) {
            setNewDealHref('/deal/new');
        } else if (!userLoggedIn) {
            setNewDealHref('/signin');
        } else {
            setNewDealHref('/verify');
        }
    }, [user]);
    return (
        <div className='flex flex-col items-center justify-center w-full'>
            <Helmet>
                <title>Pages des deals - Tunisiandeals.com</title>
                <rel link="canonical" href={window.location.href}/>
                <meta name="description" content="La page des deals"/>
                <meta name="keywords"
                      content="deals, tunisia, shopping, online, discounts, bonnes affaires, offres, tunisie"/>
            </Helmet>
            <div className={"lg:hidden"}>
                <CategoriesModal setCategory={setCategory}/>
            </div>
            {!category && page === 1 && <Welcome/>}
            {isLoading && Array.from({length: 5}).map((_, i) => {
                return (
                    <Fragment key={i}>
                        <Card className="w-[800px] space-y-5 p-4" radius="lg">
                            <Skeleton className="rounded-lg" key={`skeleton1-${i}`}>
                                <div className="h-24 rounded-lg bg-default-300"></div>
                            </Skeleton>
                            <div className="space-y-3">
                                <Skeleton className="w-3/5 rounded-lg" key={`skeleton2-${i}`}>
                                    <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                                </Skeleton>
                                <Skeleton className="w-4/5 rounded-lg" key={`skeleton3-${i}`}>
                                    <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
                                </Skeleton>
                                <Skeleton className="w-2/5 rounded-lg" key={`skeleton4-${i}`}>
                                    <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
                                </Skeleton>
                            </div>
                        </Card>
                        <Spacer x={6} />
                    </Fragment>
                )
            })}
            <Spacer x={6} />
            {category && <Card isBlurred className="border-none bg-red-500 max-w-[800px] w-full">
                <CardBody className="items-center">
                    <p>Les Deals dans {category.name}</p>
                </CardBody>
            </Card>}
            <div className={"flex"}>
                <div className={"hidden sm:flex"}>
                    {!isLoading && data.deals.length > 0 && !isError && <SideDeals/>}
                    <Spacer x={6} />
                </div>
                <div>
                    {!isLoading && data.deals.length > 0 && !isError && data.deals.map((deal, i) => {
                        return (
                            <Fragment key={i}>
                                <Spacer x={6} />
                                <DealCard deal={deal} refetch={refetch}/>
                            </Fragment>
                        )
                    })}
                </div>
            </div>
            {!isLoading && !isError && data.deals.length === 0 && <EmptyState message={"Aucun Deal trouvé pour cette catégorie"}/>}
            {!isLoadingPages && !isError && numberOfPages.pages > 0 &&
                <>
                    <Spacer x={6} />
                    <Pagination isCompact showControls total={numberOfPages.pages + 1} initialPage={page} color={'success'} onChange={
                        (e, value) => {
                            setPage(e);
                            refetch();
                        }
                    }/>
                </>
            }
            <Button
                className={"sm:hidden font-semibold"}
                as={Link} color="primary"
                variant="bordered"
                href={newDealHref}
                style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    zIndex: 1000
                }}
            >
                Poster un Deal
            </Button>
            {toast.visible && <Toast message={toast.message} type={toast.type} />}
        </div>
    );
}