import React, {useState} from "react";
import {useDataQuery} from "@/hooks/useDataQuery";
import {Card, Pagination, Skeleton, Spacer} from "@nextui-org/react";
import DealCard from "@/components/DealComponent/DealCard";
import EmptyState from "@/components/EmptyState";

export default function UserDeals() {
    const [page, setPage] = useState(1);
    const { data, isLoading, isError, refetch } = useDataQuery({url: `/api/mydeals?page=${page}`});
    const { data: numberOfPages, isLoading: isLoadingPages } = useDataQuery({url: `/api/services/deals_pages`});

    return (
        <div className='flex flex-col items-center justify-center w-full'>
            {isLoading && Array.from({length: 5}).map((_, i) => {
                return (
                    <>
                        <Card className="w-[800px] space-y-5 p-4" radius="lg" key={i}>
                            <Skeleton className="rounded-lg">
                                <div className="h-24 rounded-lg bg-default-300"></div>
                            </Skeleton>
                            <div className="space-y-3">
                                <Skeleton className="w-3/5 rounded-lg">
                                    <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                                </Skeleton>
                                <Skeleton className="w-4/5 rounded-lg">
                                    <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
                                </Skeleton>
                                <Skeleton className="w-2/5 rounded-lg">
                                    <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
                                </Skeleton>
                            </div>
                        </Card>
                        <Spacer x={6} />
                    </>
                )
            })}
            {!isLoading && !isError && data.deals.length === 0 && <EmptyState message={"Tu n'as pas encore partagé une offre. Pourquoi ne pas publier un deal maintenant ?"}/>}
            {data && !isLoading && !isError && data.deals.map((deal) => {
                return (
                    <>
                        <Spacer x={6} />
                        <DealCard deal={deal} refetch={refetch}/>
                    </>
                )
            })}
            {!isLoadingPages && !isError && numberOfPages > 1 &&
                <>
                    <Spacer x={6} />
                    <Pagination isCompact showControls total={numberOfPages.pages + 1} initialPage={page} color={'success'} onChange={
                        (e, value) => {
                            setPage(e);
                            refetch();
                        }
                    }/>
                </>
            }
        </div>
    )
}