import React, {useState} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import DealsPage from "~/pages/DealsPage";
import NavBar from "~/components/NavBar";
import CategoriesNavBar from "~/components/Categories/CategoriesNavBar";
import DealPage from "~/pages/DealPage";
import Signin from "@/pages/Signin";
import Signup from "@/pages/Signup";
import {QueryClient, QueryClientProvider} from "react-query";
import NewDeal from "~/components/DealComponent/NewDeal";
import UserProvider from "@/providers/UserProvider";
import ProfilePage from "@/pages/ProfilePage";
import Feedback from "@/pages/Feedback";
import {Divider, Link} from "@nextui-org/react";
import Verify from "~/pages/Verify";
import ResetPasswordPage from "~/pages/ResetPasswordPage";
import TermsOfUse from "~/pages/TermsOfUse";
import PrivacyPolicy from "~/pages/PrivacyPolicy";

export default function App() {

    const queryClient = new QueryClient();
    const [categrory, setCategory] = useState(null);

    return (
        <QueryClientProvider client={queryClient}>
            <UserProvider>
                <div className="w-full h-screen p-8 items-start justify-center">
                    <NavBar setCategory={setCategory}/>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<DealsPage category={categrory} setCategory={setCategory}/>}/>
                            <Route path="/new" element={<DealsPage category={categrory} setCategory={setCategory}/>}/>
                            <Route path="/hot" element={<DealsPage category={categrory} setCategory={setCategory}/>}/>
                            <Route path="/deal/:id" element={<DealPage/>}/>
                            <Route path="/deal/new/:id" element={<NewDeal/>}/>
                            <Route path="/signin" element={<Signin/>}/>
                            <Route path="/register" element={<Signup/>}/>
                            <Route path="/deal/new" element={<NewDeal/>}/>
                            <Route path="/profile" element={<ProfilePage/>}/>
                            <Route path="/feedback" element={<Feedback/>}/>
                            <Route path="/verify" element={<Verify/>}/>
                            <Route path="/resetPassword/:token" element={<ResetPasswordPage/>}/>
                            <Route path="/terms-of-use" element={<TermsOfUse/>}/>
                            <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                        </Routes>
                    </BrowserRouter>
                    <div className="w-full p-5 text-center mt-auto">
                        <div className="space-y-1">
                            Powered by
                            <h4 className="text-medium font-medium">
                                <Link href={'https://www.it-patrol.tech/'}>
                                    IT-PATROL
                                </Link>
                            </h4>
                            <p className="text-small text-default-400">Agence de consulting et de développement technologique</p>
                        </div>
                        <Divider className="my-4"/>
                        <div className="flex h-5 items-center space-x-4 text-small">
                            <div><Link href={'/terms-of-use'}>Conditions d'utilisation</Link></div>
                            <Divider orientation="vertical"/>
                            <div><Link href={'/privacy-policy'}>Politique de confidentialité</Link> </div>
                            <Divider className="hidden sm:flex" orientation="vertical"/>
                            <div className="hidden sm:flex">tous les droits sont réservés, 2024, TunisianDeals</div>
                        </div>
                    </div>
                </div>
            </UserProvider>
        </QueryClientProvider>
    )
        ;
};
