import React, { useState } from 'react';
import {Button, Card, CardBody, cn, Image, Link, Tooltip} from "@nextui-org/react";
import {ChevronIcon} from "~/components/Categories/ChevronIcon";
import {placeholder} from "@/assets";

const Carousel = ({ kind, selectedFiles, setSelectedFiles, progress, setProgress }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const isButton = kind === 'button';
    const isImg = kind === 'img';
    const numberOfShownButtons = 5;

    const handleDelete = (index) => {
        const newSelectedFiles = [...selectedFiles];
        newSelectedFiles.splice(index, 1);
        setSelectedFiles(newSelectedFiles);
        if (index > 0) {
            setCurrentImageIndex(currentImageIndex - 1);
        } else {
            setCurrentImageIndex(0);
        }
        if (newSelectedFiles.length === 0) {
            setProgress(progress - 1);
        }
    }

    const nextImage = () => {
        const newIndex = currentImageIndex + 1;
        if ( isButton && newIndex < selectedFiles.length - numberOfShownButtons + 1) {
            setCurrentImageIndex(newIndex);
        }
        if (isImg) {
            setCurrentImageIndex(newIndex >= selectedFiles.length ? 0 : newIndex);
        }
    };

    const prevImage = () => {
        const newIndex = currentImageIndex - 1;
        if ( isButton && newIndex >= 0) {
            setCurrentImageIndex(newIndex);
        }
        if (isImg) {
            setCurrentImageIndex(newIndex < 0 ? selectedFiles.length - 1 : newIndex);
        }
    };

    return (
        <>
        {isImg &&
            <div className="flex items-center justify-around w-full">
                {selectedFiles.length > 1 && <Button isIconOnly onClick={prevImage} radius="full"
                                                     className="bg-gradient-to-tr from-pink-500 to-yellow-500 text-white shadow-lg">
                    <ChevronIcon/>
                </Button>}
                <Image src={selectedFiles.length > 0 ? selectedFiles[currentImageIndex] : placeholder} width={300}
                       alt="carousel" height={300}/>
                {selectedFiles.length > 0 && !selectedFiles[0].includes('placeholder') && setSelectedFiles &&
                    <Button isIconOnly onClick={() => handleDelete(currentImageIndex)} radius="full"
                            className="absolute top-0 right-0 bg-gray-500 text-white shadow-lg">
                        X
                    </Button>}
                {selectedFiles.length > 1 && <Button isIconOnly onClick={nextImage} radius="full"
                                                     className="bg-gradient-to-tr from-pink-500 to-yellow-500 text-white shadow-lg">
                    <ChevronIcon className="rotate-180"/>
                </Button>}
            </div>
        }
        </>
    );
};

export default Carousel;