import React, {useContext, useEffect, useState} from "react";
import {
    Button,
    Card,
    CardBody,
    Input,
    Navbar, NavbarContent,
    Select,
    SelectItem,
    Spacer,
    Tab,
    Tabs,
    Progress,
    DatePicker, CardHeader
} from "@nextui-org/react";
import Delivery from "~/components/DealComponent/Delivery";
import ImageUpload from "~/components/DealComponent/ImageUplaod";
import {placeholder} from "@/assets";
import {useDataQuery} from "@/hooks/useDataQuery";
import {useParams, useNavigate} from "react-router-dom";
import {UserContext} from "@/providers/UserProvider";
import Toast from "@/components/Toast";
import {parseDate} from "@internationalized/date";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import RichTextEditor from "@/components/RichTextEditor";
import {convertFromRaw, EditorState} from "draft-js";

export default function NewDeal() {
    const navigate = useNavigate();
    const params = useParams();
    const [title, setTitle] = useState('');
    const [price, setPrice] = useState('');
    const [errorPrice, setErrorPrice] = useState('');
    const [errorPriceMessage, setErrorPriceMessage] = useState('');
    const [generallyObservedPrice, setGenerallyObservedPrice] = useState('');
    const [errorGenerallyObservedPrice, setErrorGenerallyObservedPrice] = useState('');
    const [errorGenerallyObservedPriceMessage, setErrorGenerallyObservedPriceMessage] = useState('');
    const [promoCode, setPromoCode] = useState('');
    const [seller, setSeller] = useState('');
    const [category, setCategory] = useState('');
    const [startDate, setStartDate] = useState(parseDate(new Date().toISOString().split('T')[0]));
    const [expiryDate, setExpiryDate] = useState(parseDate(new Date().toISOString().split('T')[0]));
    const [dealType, setDealType] = useState('');
    const [dealUrl, setDealUrl] = useState('');
    const [description, setDescription] = useState('');
    const [progress, setProgress] = useState(0);
    const [selectedFiles, setSelectedFiles] = useState([placeholder]);
    const [deliveryCost, setDeliveryCost] = useState(0);
    const [location, setLocation] = useState('');
    const [dealId, setDealId] = useState(params.id);
    const { data, isLoading, isError, refetch } = useDataQuery({url: '/api/categories'});
    const {user, isLoadingUser} = useContext(UserContext);
    const dealIdUrl = dealId ? `/api/deals/${dealId}` : '/api/deals/invalid';
    const [dealToUpdate, setDealToUpdate] = useState(null);
    const [isLoadingDealToUpdate, setIsLoadingDealToUpdate] = useState(true);
    const [isErrorDealToUpdate, setIsErrorDealToUpdate] = useState(false);
    const [descriptionEditorState, setDescriptionEditorState] = useState('');

    useEffect(() => {
        if (user && user.error) navigate('/signin');
        if (!dealId) return;
      fetchData(dealIdUrl)
        .then(data => {
          setDealToUpdate(data);
          setIsLoadingDealToUpdate(false);
        })
        .catch(error => {
          console.error('Failed to fetch deal:', error);
          setIsErrorDealToUpdate(true);
          setIsLoadingDealToUpdate(false);
        });
    }, [dealIdUrl, isLoadingUser]);


    async function fetchData(url) {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    }

    useEffect(() => {
        if (dealToUpdate) {
            if (!isLoadingDealToUpdate && !isErrorDealToUpdate) {
                let newProgress = 0;
                setTitle(dealToUpdate.title);
                if (dealToUpdate.title.length >= 1) newProgress++;
                setPrice(dealToUpdate.price);
                setGenerallyObservedPrice(dealToUpdate.observed_price);
                setPromoCode(dealToUpdate.coupon);
                setSeller(dealToUpdate.merchant);
                if (dealToUpdate.merchant.length >= 1) newProgress++;
                setDescription(dealToUpdate.description);
                setCategory(dealToUpdate.category.id);
                newProgress++;
                if (dealToUpdate.description) {
                    newProgress++;
                    setDescription(JSON.parse(dealToUpdate.description));
                    setDescriptionEditorState(dealToUpdate.description);
                }
                if (dealToUpdate.link != null) {
                    setDealUrl(dealToUpdate.link);
                    newProgress++;
                }
                if (dealToUpdate.images.length >= 1) {
                    newProgress++;
                    setSelectedFiles(dealToUpdate.images);
                }
                if (dealToUpdate.start) setStartDate(parseDate(dealToUpdate.start.split('T')[0]));
                if (dealToUpdate.expiration) setExpiryDate(parseDate(dealToUpdate.expiration.split('T')[0]));
                setLocation(dealToUpdate.instore);
                setProgress(newProgress);
            }
        }
    }, [dealToUpdate]);

    if (!isLoadingUser && !user) {
        navigate('/signin');
        return null;
    }

    if (dealToUpdate &&!isLoadingUser && user.id !== dealToUpdate.user_id) {
        navigate('/');
        return null;
    }

    const handleTitleChange = (e) => {
        if (e.target.value === ' ') return;
        if (e.target.value.length >= 1 && title.length === 0) setProgress(progress + 1);
        setTitle(e.target.value);
        if (e.target.value.length === 0) setProgress(progress - 1);
    };
    const handleCategoryChange = (e) => {
        if (e.target.value.length >= 1 && category === '') setProgress(progress + 1);
        setCategory(parseInt(e.target.value) + 1);
    }

    const runPriceValidation = (e) => {
        if (parseInt(e) < 0) {
            setErrorPrice(true);
            setErrorPriceMessage('Le prix ne peut pas être négatif');
            setPrice('');
            return;
        }
        if (e === 0) {
            setPrice('');
        } else {
            setPrice(e);
        }
        if (parseInt(e) > parseInt(generallyObservedPrice) || (parseInt(e) > 0 && generallyObservedPrice === '')) {
            setErrorPrice(true);
            setErrorPriceMessage('Le prix ne peut pas être supérieur au prix généralement observé');
            setErrorGenerallyObservedPrice(true);
            return;
        }
        setErrorPrice(false);
        setErrorGenerallyObservedPrice(false);
    }
    const handlePriceChange = (e) => {
        runPriceValidation(e.target.value)
    };

    const runGPValidation = (e) => {
        if (parseInt(e) < 0) {
            setErrorGenerallyObservedPrice(true);
            setErrorGenerallyObservedPriceMessage('Le prix observé ne peut pas être négatif');
            setGenerallyObservedPrice('');
            return;
        }
        if (e === 0) {
            setGenerallyObservedPrice('');
        } else {
            setGenerallyObservedPrice(e);
        }
        if (parseInt(e) < parseInt(price)) {
            setErrorGenerallyObservedPrice(true);
            setErrorGenerallyObservedPriceMessage('Le prix observé ne peut pas être inférieur au prix');
            setErrorPrice(true);
            return;
        }
        setErrorPrice(false);
        setErrorGenerallyObservedPrice(false);
    }
    const handleGenerallyObservedPriceChange = (e) => {
        runGPValidation(e.target.value);
    };
    const handlePromoCodeChange = (e) => {
        if (e.target.value === ' ') return;
        setPromoCode(e.target.value);
    }
    const handleSellerChange = (e) => {
        if (e.target.value === ' ') return;
        if (e.target.value.length >= 1 && seller === '') setProgress(progress + 1);
        setSeller(e.target.value);
        if (e.target.value.length === 0) setProgress(progress - 1);
    };
    const handleStartDateChange = (date) => setStartDate(date);
    const handleExpiryDateChange = (date) => setExpiryDate(date);
    const handleDealTypeChange = (e) => setDealType(e.target.value);
    const handleDescriptionChange = (e) => {
        if (e.blocks[0].text === '' && description === '') return;
        let parsedDescription = null;
        try {
            parsedDescription = JSON.parse(description);
        } catch (error) {
            parsedDescription = description;
        }
        if (e.blocks[0].text.length >= 1 && (description.length === 0 || (parsedDescription.blocks && parsedDescription.blocks[0].text.length === 0))) setProgress(progress + 1);
        setDescription(e);
        if (e.blocks[0].text.length === 0 && description.blocks[0].text.length !== 0) setProgress(progress - 1);
    };
    let tabs = [
        {
            id: "online",
            label: "En ligne",
        },
        {
            id: "local",
            label: "En magasin",
        },
        {
            id: "both",
            label: "En ligne et en magasin",
        }
    ];

    /*const fetchDealContent = async () => {
        if (deal_url === '') return;
        const response = await fetch(`/api/services/deal_content?url=${deal_url}`);
        const data = await response.json();
        return data;
    };
    const { dealContent, isLoadingDealContent, dealContentHasError, refetchDealContent } = useQuery('deals', fetchDealContent);*/

    let items = []
    if (!isLoading) items = data.map((category) => {
        return {name: category.name, description: category.description}
    })

    async function createDeal(deal) {
        const response = await fetch('/api/deals', {
            method: 'POST',
            body: deal,
        });

        if (!response.ok) {
            showError('Erreur lors de la création du deal. Vérifiez les informations et réessayez.')
        }

        const data = await response.json();
        return data;
    }

    async function UpdateDeal(deal) {
        const response = await fetch(`/api/deals/${deal.get('id')}`, {
            method: 'PUT',
            body: deal,
        });

        if (!response.ok) {
            showError('Erreur lors de la mise à jour du deal. Vérifiez les informations et réessayez.')
        }

        const data = await response.json();
        return data;
    }

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('title', title);
        formData.append('price', price);
        formData.append('observed_price', generallyObservedPrice);
        formData.append('coupon', promoCode);
        formData.append('merchant', seller);
        formData.append('category_id', category);
        if (startDate !== expiryDate) {
            formData.append('start', startDate);
            formData.append('expiration', expiryDate);
        }
        formData.append('link', dealUrl);
        formData.append('description', JSON.stringify(description));
        formData.append('instore', location);
        selectedFiles.forEach((file, i) => {
            formData.append(`images[]`, file);
        });

        try {
            if (dealId) {
                formData.append('id', dealToUpdate.id);
                const updatedDeal = await UpdateDeal(formData);
                showSuccess('Deal mis à jour avec succès.');
                if (updatedDeal) window.location = `/deal/${updatedDeal.id}`;
            } else {
                const newDeal = await createDeal(formData);
                showSuccess('Deal créé avec succès.');
                if (newDeal) window.location = `/deal/${newDeal.id}`;
            }
        } catch (error) {
            const message = dealId ? 'Erreur lors de la mise à jour du deal. Vérifiez les informations et réessayez.' : 'Erreur lors de la création du deal. Vérifiez les informations et réessayez.';
            showError(message);
        }
    };

    const [toast, setToast] = useState({ message: '', type: '', visible: false });

    const showError = (message) => {
        setToast({ message: message, type: 'error', visible: true });
        setTimeout(() => setToast({ ...toast, visible: false }), 3000);
    };

    const showSuccess = (message) => {
        setToast({ message: message, type: 'success', visible: true });
        setTimeout(() => setToast({ ...toast, visible: false }), 3000);
    };

    return (
        <div className='flex flex-col items-center justify-center w-full'>
            <Navbar isBordered className={'flex-col items-center justify-center'}>
                <NavbarContent justify={'start'}>
                    {!dealId && <h1 className="text-2xl font-bold">Nouveau deal</h1>}
                    {dealId && <h1 className="text-2xl font-bold">Modifier le deal</h1>}
                </NavbarContent>
                <NavbarContent className={"hidden sm:flex"}>
                    <Progress
                        label={`Remplir les détails du deal: ${progress}/6`}
                        size="md"
                        radius="md"
                        classNames={{
                            base: "max-w-md",
                            track: "drop-shadow-md border border-default",
                            indicator: "bg-gradient-to-r from-pink-500 to-yellow-500",
                            label: "tracking-wider font-medium text-default-600",
                            value: "text-foreground/60",
                        }}
                        value={progress}
                        maxValue={6}
                        className="max-w-md"
                    />
                </NavbarContent>
                <NavbarContent className={"sm:hidden"}>
                    <Progress
                        label={`${progress}/6`}
                        size="md"
                        radius="md"
                        classNames={{
                            base: "max-w-md",
                            track: "drop-shadow-md border border-default",
                            indicator: "bg-gradient-to-r from-pink-500 to-yellow-500",
                            label: "tracking-wider font-medium text-default-600",
                            value: "text-foreground/60",
                        }}
                        value={progress}
                        maxValue={6}
                        className="max-w-md"
                    />
                </NavbarContent>
                <NavbarContent justify={'end'} className={"z-50"}>
                    <Button radius="full"
                            className="bg-gradient-to-tr from-pink-500 to-yellow-500 text-white shadow-lg"
                            isDisabled={progress !== 6}
                            onClick={handleSubmit}
                    >
                        {dealId ? 'Mettre à jour' : 'Créer'}
                    </Button>
                </NavbarContent>
            </Navbar>
            <Spacer x={6}/>
            <ImageUpload selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} setProgress={setProgress}
                         progress={progress}
            />
            <Spacer x={36}/>
            <Card className="w-full max-w-[800px] p-4">
                <Spacer x={6}/>
                <Input
                    type="text"
                    label="Titre"
                    placeholder="Donner un titre court et descriptif à votre deal..."
                    labelPlacement="outside"
                    characterLimit={140}
                    value={title}
                    onChange={handleTitleChange}
                    onClear={() => {
                        setTitle('');
                        setProgress(progress - 1);
                    }}
                    isClearable
                    isRequired
                />
                <Spacer x={6}/>
                <h1>Détails du prix</h1>
                <div className="flex flex-col gap-2">
                    <div className="flex w-full flex-wrap md:flex-nowrap mb-6 md:mb-0 gap-2">
                        <Input
                            type="number"
                            label="Prix"
                            placeholder="0.00"
                            value={price}
                            onChange={handlePriceChange}
                            onClear={() => runPriceValidation(0)}
                            labelPlacement="outside"
                            color={errorPrice ? 'danger' : 'success'}
                            isInvalid={errorPrice}
                            errorMessage={errorPrice && errorPriceMessage}
                            startContent={
                                <div className="pointer-events-none flex items-center">
                                    <span className="text-default-400 text-small">DT</span>
                                </div>
                            }
                        />
                        <Input
                            type="number"
                            label="Prix généralement constaté"
                            placeholder="0.00"
                            value={generallyObservedPrice}
                            onChange={handleGenerallyObservedPriceChange}
                            onClear={() => runGPValidation(0)}
                            labelPlacement="outside"
                            color={errorGenerallyObservedPrice ? 'danger' : 'success'}
                            isInvalid={errorGenerallyObservedPrice}
                            errorMessage={errorGenerallyObservedPrice && errorGenerallyObservedPriceMessage}
                            startContent={
                                <div className="pointer-events-none flex items-center">
                                    <span className="text-default-400 text-small">DT</span>
                                </div>
                            }
                        />
                    </div>
                    <div className="flex w-full flex-wrap md:flex-nowrap mb-6 md:mb-0 gap-2">
                        <Input
                            type="text"
                            label="Code promo"
                            value={promoCode}
                            onChange={handlePromoCodeChange}
                            onClear={() => setPromoCode('')}
                            placeholder="Inscrivez le code promo ici..."
                            labelPlacement="outside"
                            isClearable
                        />
                        <Input
                            type="text"
                            label="Vendeur"
                            value={seller}
                            onChange={handleSellerChange}
                            onClear={() => {
                                setSeller('');
                                setProgress(progress - 1);
                            }}
                            placeholder="Donner le nom du vendeur..."
                            labelPlacement="outside"
                            isClearable
                            isRequired
                        />
                    </div>
                        <Select
                            isRequired
                            label="Catégorie"
                            value={category}
                            defaultSelectedKeys={dealId ? [category + 0] : ''}
                            onChange={handleCategoryChange}
                            placeholder="Choisir une catégorie"
                            className="max-w-[100%] w-full"
                            labelPlacement={'outside'}
                        >
                            {!isLoading && data.map((category, i) => (
                                <SelectItem key={i} value={category.name}>
                                    {category.name}
                                </SelectItem>
                            ))}
                        </Select>
                        <div className="flex w-full flex-wrap">
                            <div className="flex flex-col gap-2 w-1/2">
                                <DatePicker value={startDate} label={'Date du début'} onChange={handleStartDateChange} size={'large'}/>
                            </div>
                            <div className="flex flex-col gap-2 w-1/2">
                                <DatePicker value={expiryDate} label={"Date d'expiration"} onChange={handleExpiryDateChange} size={'large'}/>
                            </div>
                        </div>
                        <div className="flex w-full flex-col">
                            <Tabs aria-label="Dynamic tabs" items={tabs}>
                                {(item) => (
                                    <Tab key={item.id} title={item.label}>
                                        <Card>
                                            <CardBody>
                                                {<Delivery type={item.id} progress={progress} setProgress={setProgress}
                                                dealUrl={dealUrl} setDealUrl={setDealUrl} deliveryCost={deliveryCost}
                                                setDeliveryCost={setDeliveryCost} location={location}
                                                           setLocation={setLocation} dealId={dealId}
                                                />}
                                            </CardBody>
                                        </Card>
                                    </Tab>
                                )}
                            </Tabs>
                        </div>
                    </div>

            </Card>
            <Spacer x={36}/>
            <Card className="w-full max-w-[800px] p-4 min-h-[400px] h-auto">
                <CardHeader>Déscription<span className={'text-red-500'}>*</span></CardHeader>
                <Spacer x={6}/>
                <RichTextEditor setText={handleDescriptionChange} initialValue={descriptionEditorState}/>
            </Card>
            {toast.visible && <Toast message={toast.message} type={toast.type} />}
        </div>
);
}