import {useMemo} from "react";
import {useQuery} from "react-query";


export const useDataQuery = ({url}) => {
    const query = useMemo(() => {
        return async () => {
            const response = await fetch(url);
            return response.json();
        }
    }, [url]);

    return useQuery(url, query)

}