import React, {useEffect, useMemo, useState} from "react";
import {Accordion, AccordionItem, Button, Checkbox, Divider, Input, Spacer, Tooltip} from "@nextui-org/react";
import Toast from "@/components/Toast";

export default function UserInfos({user, isLoadingUser, refetchUser}) {
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState(user.email);
    const [username, setUsername] = useState(user.user_name);
    const [name, setName] = useState(user.name);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [acceptMarketing, setAcceptMarketing] = useState(false);
    const validateEmail = (value) => value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i);
    const isInvalid = useMemo(() => {
        if (email === "") return false;

        return validateEmail(email) ? false : true;
    }, [email]);

    useEffect(() => {
        if (!isLoadingUser) setAcceptMarketing(user.accept_marketing);
    }, []);

    const isInvalidPassword = useMemo(() => {
        if (password.length !== 0 && newPassword !== confirmNewPassword && newPassword.length !== 0) return false;
    }, [password, newPassword, confirmNewPassword]);

    let userData = {};
    function updateUserDetails() {
        if (name) userData.name = name;
        if (email) userData.email = email;
        if (username) userData.user_name = username;
        if (password) userData.current_password = password;
        if (acceptMarketing != user.accept_marketing) userData.accept_marketing = acceptMarketing;
        fetch('/signup', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                // Include other headers as needed, such as authentication tokens
            },
            body: JSON.stringify({
                user: userData
            })
        })
        .then(response => response.json())
        .then(data => {
            refetchUser();
            showSuccess(email !== user.email ? 'Adresse mail changée avec succès. Un email de confirmation a été envoyé.' : 'Informations mises à jour avec succès.');
        })
        .catch((error) => {
            showError('Erreur lors de la mise à jour des informations. Verifie les informations et réessaye.');
        });
    }

    function checkPassword() {
        fetch(`/api/services/check_password?password=${password}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                // Include other headers as needed, such as authentication tokens
            }
        })
        .then(response => response.json())
        .then(data => {
            setIsPasswordValid(data.valid);
            data.valid ? showSuccess('Mot de passe correct. Tu peux maintenant modifier tes informations.') : showError('Mot de passe incorrect. Verifie le mot de passe et réessaye.');
        })
        .catch((error) => {
            showError('Erreur lors de la vérification du mot de passe. Verifie le mot de passe et réessaye.');
        });
    }


    const [toast, setToast] = useState({ message: '', type: '', visible: false });

    const showError = (message) => {
        setToast({ message: message, type: 'error', visible: true });
        setTimeout(() => setToast({ ...toast, visible: false }), 3000);
    };

    const showSuccess = (message) => {
        setToast({ message: message, type: 'success', visible: true });
        setTimeout(() => setToast({ ...toast, visible: false }), 3000);
    };

    return (
        <div className={"border-none bg-background/60 dark:bg-default-100/50 max-w-[800px] w-full"}>
            <span className={"flex font-light justify-center w-full p-3"}>Voir et modifier les informations personelles liées au compte Tunisian Deals. Tous les changements sont irreversible.</span>
            <div className="my-1 p-8 rounded-2xl flex justify-center items-center bg-gradient-to-tr from-pink-500 to-yellow-500 text-white shadow-lg">
                <Input type="password" label="Donner le mot de passe actuel pour continuer"
                       placeholder="Mot de passe actuel"
                       isInvalid={isInvalidPassword} color={isInvalidPassword ? 'danger' : 'success'}
                       errorMessage={"Le mot de passe est incorrect"}
                       radius="lg"
                       classNames={{
                           label: "text-black/50 dark:text-white/90",
                           input: [
                               "bg-transparent",
                               "text-black/90 dark:text-white/90",
                               "placeholder:text-default-700/50 dark:placeholder:text-white/60",
                           ],
                           innerWrapper: "bg-transparent",
                           inputWrapper: [
                               "shadow-xl",
                               "bg-default-200/50",
                               "dark:bg-default/60",
                               "backdrop-blur-xl",
                               "backdrop-saturate-200",
                               "hover:bg-default-200/70",
                               "dark:hover:bg-default/70",
                               "group-data-[focused=true]:bg-default-200/50",
                               "dark:group-data-[focused=true]:bg-default/60",
                               "!cursor-text",
                           ],
                       }}
                       isClearable
                       onChange={e => setPassword(e.target.value)}
                       onClear={() => {
                           setPassword('')
                       }}
                />
                <Button variant={"light"} isDisabled={password.length === 0} onClick={checkPassword}>Confirmer</Button>
            </div>

            {!isLoadingUser && <Accordion variant={"splitted"} fullWidth={true}>
                <AccordionItem key="1" aria-label="Accordion 1" title="Nom" isDisabled={!isPasswordValid}>
                    {user.name}
                    <Divider orientation={'horizontal'}/>
                    <Spacer x={6}/>
                    <Input type="text" variant={'underlined'} label="Nouveau nom" isClearable
                           onClear={() => setName('')} onChange={e => setName(e.target.value)}
                    />
                    <Spacer x={6}/>
                    <Button color={'success'}
                            isDisabled={name.length === 0}
                            onClick={updateUserDetails}
                    >
                        Changer le nom
                    </Button>
                </AccordionItem>
                <AccordionItem key="2" aria-label="Accordion 2" title="Adresse Mail" isDisabled={!isPasswordValid}>
                    {user.email}
                    <Divider orientation={'horizontal'}/>
                    <Spacer x={6}/>
                    <Input type="email" variant={'underlined'} label="Nouuvelle adresse mail"
                           placeholder="Changer l'email"
                           isClearable onChange={e => setEmail(e.target.value)}
                           isInvalid={isInvalid} color={isInvalid ? 'danger' : 'success'}
                           onClear={() => {
                               setEmail('')
                           }}
                           errorMessage={isInvalid && "L'email n'est pas valide"}
                    />
                    <Spacer x={6}/>
                    <Button color={'success'}
                            isDisabled={email.length === 0}
                            onClick={updateUserDetails}>
                        Changer l'adresse mail
                    </Button>
                </AccordionItem>
                <AccordionItem key="3" aria-label="Accordion 3" title="Nom d'utilisateur" isDisabled={!isPasswordValid}>
                    {user.user_name}
                    <Divider orientation={'horizontal'}/>
                    <Spacer x={6}/>
                    <Input type="text" variant={'underlined'} label="Nouveau nom d'utilisateur" isClearable
                           onClear={() => setUsername('')} onChange={e => setUsername(e.target.value)}
                    />
                    <Spacer x={6}/>
                    <Button color={'success'} isDisabled={username.length === 0} onClick={updateUserDetails}>
                        Changer le nom d'utilisateur
                    </Button>
                </AccordionItem>
                <AccordionItem key="4" aria-label="Accordion 4" title="Changer le mot de passe" isDisabled={!isPasswordValid}>
                    <Input type="password" variant={'underlined'} label="Nouveau mot de passe"
                           placeholder="Nouveau mot de passe"
                           isInvalid={isInvalidPassword} color={isInvalidPassword ? 'danger' : 'success'}
                           errorMessage={"verifier le mot de passe"}
                           isClearable
                           onChange={e => setNewPassword(e.target.value)}
                           onClear={() => {
                               setNewPassword('')
                           }}
                    />
                    <Input type="password" variant={'underlined'} label="Confirmer le nouveau mot de passe"
                           placeholder="Confirmer le nouveau mot de passe"
                           isInvalid={isInvalidPassword} color={isInvalidPassword ? 'danger' : 'success'}
                           errorMessage={"verifier le mot de passe"}
                           isClearable
                           onChange={e => setConfirmNewPassword(e.target.value)}
                           onClear={() => {
                               setConfirmNewPassword('')
                           }}
                    />
                    <Spacer x={6}/>
                    <Button color={'success'} isDisabled={newPassword.length === 0} onClick={updateUserDetails}>
                        Changer le mot de passe
                    </Button>
                </AccordionItem>
                <AccordionItem key="5" aria-label="Accordion 5" title="Accepter les emails marketing" isDisabled={!isPasswordValid}>
                    <Tooltip content="Coche cette case si tu souhaite recevoir des emails marketing de notre part">
                        <Checkbox value={acceptMarketing} color="success" onChange={() => setAcceptMarketing(!acceptMarketing)}>Recevoir par Mails nos news</Checkbox>
                    </Tooltip>
                    <Spacer x={6}/>
                    <Button color={'success'} onClick={updateUserDetails}>
                        Mettre à jour les préférences
                    </Button>
                </AccordionItem>
            </Accordion>}
            {toast.visible && <Toast message={toast.message} type={toast.type} />}
        </div>
    )
}