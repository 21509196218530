import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Image,
    Input,
    Link,
    Skeleton,
    Spacer,
    Tooltip
} from "@nextui-org/react";
import {tdlogo} from "~/assets";
import Toast from "~/components/Toast";

export default function ResetPasswordPage() {

    let path = window.location.pathname; // "/resetPassword/1234"
    let parts = path.split('/'); // ["", "resetPassword", "1234"]
    let token = parts[parts.length - 1]; // "1234"
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [toast, setToast] = useState({ message: '', type: '', visible: false });

    useEffect(() => {
        resetPasswordToken();
    }, []);

    const resetPasswordToken = () => {
        fetch(`/password/edit?reset_password_token=${token}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        }).then((res) => res.json()).then((data) => {
            if (data.status === 'ok') {
                showSuccess('Identité confirmée, tu peux maintenant saisir ton nouveau mot de passe');
            } else {
                showError('Une erreur est survenue, reessaye plus tard.');
            }
        })
    }

    const showError = (message) => {
        setToast({ message: message, type: 'error', visible: true });
        setTimeout(() => setToast({ ...toast, visible: false }), 3000);
    };

    const showSuccess = (message) => {
        setToast({ message: message, type: 'success', visible: true });
        setTimeout(() => setToast({ ...toast, visible: false }), 3000);
    };
    const handleSubmit = () => {
        fetch(`/password`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user: {
                    reset_password_token: token, // the reset password token
                    password: password, // the new password
                    password_confirmation: confirmPassword // the new password confirmation
                }
            })
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                if (data.code === 200) {
                    showSuccess('Le mot de passe a été modifié avec succès');
                    setTimeout(() => window.location.href = '/login', 2000);
                } else {
                    showError('Une erreur est survenue, reessaye plus tard.');
                    setTimeout(() => window.location.href = `/resetPassword/${token}`, 2000);
                }
            }).catch((error) => {
                console.error(error);
            })

    }

    return (
        <div className='flex flex-col items-center justify-center w-full'>
            <Helmet>
                <title>Reset Password - Tunisiandeals.com</title>
                <rel link="canonical" href={window.location.href}/>
                <meta name="description" content="sign in page"/>
                <meta name="keywords"
                      content="deals, tunisia, shopping, online, discounts, bonnes affaires, offres, tunisie"/>
            </Helmet>
            <>
                <Spacer x={6}/>
                <Card isBlurred className="border-none dark:bg-default-100/50 max-w-[800px] w-full">
                    <CardHeader className='flex-col justify-center font-semibold'>
                        <Image src={tdlogo} width={300} height={300} alt="Tunisian Deals Logo"/>
                        <span>Saisie ton nouveau mot de passe</span>
                    </CardHeader>
                    <CardBody className="items-center">
                        <Input type="password" variant={'underlined'} label="Mot de passe" placeholder="ton nouveau mot de passe"
                               isInvalid={password.length < 6} errorMessage={password.length < 6 ? 'Le mot de passe doit contenir au moins 6 caractères' : ''}
                               isClearable isRequired onChange={e => setPassword(e.target.value)}/>
                        <Input type="password" variant={'underlined'} label="Confirmer le mot de passe"
                               placeholder="Pour être sûr de ce que tu as tapé"
                               isInvalid={confirmPassword !== password} errorMessage={confirmPassword !== password ? 'Les mots de passe ne correspondent pas' : ''}
                               isClearable isRequired onChange={e => setConfirmPassword(e.target.value)}/>
                        <Spacer x={6} />
                    </CardBody>
                    <CardFooter className='flex justify-center'>
                        <Button className="font-semibold" onClick={handleSubmit}>
                            Confirmer
                        </Button>
                    </CardFooter>
                </Card>
            </>
            {toast.visible && <Toast message={toast.message} type={toast.type} />}
        </div>
    );
}