import React, {useContext, useEffect, useMemo, useState} from "react";
import {
    Card,
    CardBody, Chip, Link,
    Spacer,
    Tab,
    Tabs
} from "@nextui-org/react";
import UserDeals from "@/components/DealComponent/UserDeals";
import {UserContext} from "@/providers/UserProvider";
import UserInfos from "@/components/UserInfos";
import {Helmet} from "react-helmet";

export default function ProfilePage() {

    const {user, isLoadingUser, refetchUser, userLoggedIn} = useContext(UserContext);

    let tabs = [
        {
            id: "deals",
            label: "Mes Deals",
            content: <UserDeals/>
        },
        {
            id: "infos",
            label: "Mes Infos",
            content: <UserInfos user={user} isLoadingUser={isLoadingUser} refetchUser={refetchUser}/>
        }
    ];

    useEffect(() => {
        if (!userLoggedIn) {
            window.location.href = '/signin';
        }
    }, []);

    return (
        <div className="flex w-full flex-col items-center justify-center">
            <Helmet>
                <title>Profile - Tunisiandeals.com</title>
                <meta name="description" content="the user profile"/>
                <rel link="canonical" href={window.location.href}/>
                <meta name="keywords"
                      content="deals, tunisia, shopping, online, discounts, bonnes affaires, offres, tunisie"/>
            </Helmet>
            {userLoggedIn && <>
                <Spacer x={6}/>
                {!isLoadingUser && <Card isBlurred className="border-none max-w-[1000px] w-full">
                    <CardBody className="items-center font-semibold">
                        <p>Bonjour, {user.name} </p>
                        <Spacer x={2}/>
                        {
                            user.verified && <Chip color="success">Compte vérifié</Chip>
                        }
                        {
                            !user.verified && <Chip color="warning"><Link href={'/verify'}>Compte non vérifié</Link></Chip>
                        }
                    </CardBody>
                </Card>
                }
                <Spacer x={6}/>
                <Tabs className={'hidden sm:flex'} aria-label="Dynamic tabs" items={tabs} isVertical={true}>
                    {(item) => (
                        <Tab className={'hidden sm:flex'} key={item.id} title={item.label}
                             isDisabled={item.id === 'alarms' || item.id === 'stats'}>
                            {item.content}
                        </Tab>
                    )}
                </Tabs>

                <Tabs className={'lg:hidden md:hidden max-w-[800px]'} aria-label="Dynamic tabs" items={tabs} isVertical={false}>
                    {(item) => (
                        <Tab className={'lg:hidden md:hidden'} key={item.id} title={item.label}
                             isDisabled={item.id === 'alarms' || item.id === 'stats'}>
                            {item.content}
                        </Tab>
                    )}
                </Tabs>
            </>}
        </div>
    )
}