import React, {useEffect, useState} from "react";
import {Button, Card, CardFooter, CardHeader, Spacer, Image, Link} from "@nextui-org/react";
import {mainlogo} from "@/assets";
import {useDataQuery} from "@/hooks/useDataQuery";

export default function SideDeals() {

    const [currentDealIndex, setCurrentDealIndex] = useState(0);
    const { data, isLoading, isError, refetch } = useDataQuery({url: `/api/deals?category_id=13`});
    const [deal, setDeal] = useState(null);
    useEffect(() => {
        if (data && !isLoading && !isError && data.deals.length > 0) {
            setDeal(data.deals[currentDealIndex]);
        }
        const intervalId = setInterval(() => {
            setCurrentDealIndex((currentDealIndex + 1) % data.deals.length);
        }, 5000);

        return () => clearInterval(intervalId); // Clear the interval when the component unmounts
    }, [currentDealIndex, data]);

    return (
        <div>
            {deal && !isLoading && <Card isFooterBlurred className="w-full h-[600px] max-w-[270px] col-span-12 sm:col-span-7">
                <CardHeader className="absolute bg-black/40 z-10 flex-col items-start backdrop-blur">
                    <p className="text-tiny text-white/60 uppercase font-bold">{deal.merchant}</p>
                    <h4 className="text-white/60 font-medium text-xl">{deal.title}</h4>
                </CardHeader>
                <Image
                    removeWrapper
                    isZoomed
                    alt="Relaxing app background"
                    className="z-0 w-full h-full object-cover"
                    src={deal.images[0]}
                />
                <CardFooter className="absolute bg-black/40 bottom-0 z-10 dark:border-default-100">
                    <div className="flex flex-grow gap-2 items-center">
                        <Image
                            alt="tunisian deals logo"
                            className="rounded-small w-10 h-11 bg-black"
                            src={mainlogo}
                        />
                        <div className="flex flex-col">
                            {deal.price && <p className="text-tiny text-white/60">{deal.price}DT</p>}
                            {!deal.price && <p className="text-tiny text-white/60">GRATUIT</p>}
                            <p className="text-tiny text-white/60">By {deal.user.user_name}</p>
                        </div>
                    </div>
                    <Button as={Link} radius="full" size="sm" href={`/deal/${deal.id}`}>Voir le deal</Button>
                </CardFooter>
            </Card>}
        </div>
)
}