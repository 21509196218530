import React, {useContext, useEffect, useState} from "react";
import {
    Navbar,
    NavbarBrand,
    NavbarContent,
    NavbarItem,
    Link,
    Input,
    DropdownItem,
    DropdownTrigger,
    Dropdown,
    DropdownMenu,
    Avatar,
    NavbarMenu, NavbarMenuToggle, Button, NavbarMenuItem, Chip, Image
} from "@nextui-org/react";
import {mainlogo} from "@/assets";
import {SearchIcon} from "./SearchIcon.jsx";
import {SunIcon} from "~/components/SunIcon";
import {MoonIcon} from "~/components/MoonIcon";
import {UserContext} from "@/providers/UserProvider";
import {useDataQuery} from "@/hooks/useDataQuery";
import CategoriesNavBar from "@/components/Categories/CategoriesNavBar";

export default function NavBar({setCategory}) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [darkMode, setDarkMode] = useState(false);
    const {userLoggedIn, logIn, logOut, setUserLoggedIn, user} = useContext(UserContext);
    const { data, isLoading, isError } = useDataQuery({url: `/api/services/current_user`});
    const [newDealHref, setNewDealHref] = useState('');

    useEffect(() => {
        if (!isLoading) {
            if (data.error) {
                setUserLoggedIn(false);
            } else {
                setUserLoggedIn(true);
            }
            if (data.verified && userLoggedIn) {
                setNewDealHref('/deal/new');
            } else if (!userLoggedIn) {
                setNewDealHref('/signin');
            } else {
                setNewDealHref('/verify');
            }
        }
    }, [isLoading, data]);
    // window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    useEffect(() => {
        if (darkMode) {
            document.getElementsByTagName('body')[0].classList.add('dark');
        }
    }, []);

    return (
        <Navbar isBordered isMenuOpen={isMenuOpen} onMenuOpenChange={setIsMenuOpen}>
            <NavbarContent className="sm:hidden" justify="start">
                <NavbarMenuToggle aria-label={isMenuOpen ? "Close menu" : "Open menu"} />
            </NavbarContent>

            <NavbarContent className="sm:hidden" justify="center">
                <NavbarBrand>
                    <Image src={mainlogo} width={40} height={40} alt="Tunisian Deals Logo"/>
                    <Link href={'/'} className="font-bold text-inherit">TUNISIAN DEALS</Link>
                    <Chip size={'sm'} color="secondary">BETA!</Chip>
                </NavbarBrand>
            </NavbarContent>

            <NavbarContent className="hidden sm:flex gap-4" justify="center">
                <NavbarBrand>
                    <Image src={mainlogo} width={40} height={40} alt="Tunisian Deals Logo"/>
                    <Link className="font-bold text-inherit" href={'/'}>TUNISIAN DEALS</Link>
                    <Chip size={'sm'} color="secondary">BETA!</Chip>
                </NavbarBrand>
                <NavbarItem>
                    <Link className="font-semibold text-red-500" href="/">
                        Actuellement Hot
                    </Link>
                </NavbarItem>
                <NavbarItem isActive>
                    <Link href="/new" aria-current="page" color="warning">
                        Nouveaux
                    </Link>
                </NavbarItem>
                <NavbarItem>
                    <Link color="foreground" className="font-semibold" href="/hot">
                        Highlights
                    </Link>
                </NavbarItem>
                {false && <Input
                    classNames={{
                        base: "max-w-full sm:max-w-[10rem] h-10",
                        mainWrapper: "h-full",
                        input: "text-small",
                        inputWrapper: "h-full font-normal text-default-500 bg-default-400/20 dark:bg-default-500/20",
                    }}
                    placeholder="Type to search..."
                    size="sm"
                    startContent={<SearchIcon size={18}/>}
                    type="search"
                />}
            </NavbarContent>

            {userLoggedIn && !isLoading && <NavbarContent as="div" className="items-center" justify="end">
                <Dropdown placement="bottom-end">
                    <DropdownTrigger>
                        <Avatar
                            isBordered
                            as="button"
                            className="transition-transform"
                            color="secondary"
                            name={ isLoading ? 'Test User' : data.name}
                            size="sm"
                            src={`https://ui-avatars.com/api/?name=${ isLoading ? 'Test User' : data.user_name}`}
                        />
                    </DropdownTrigger>
                    <DropdownMenu aria-label="Profile Actions" variant="flat">
                        <DropdownItem key="profile" className="h-14 gap-2">
                            <p className="font-semibold">Connecté en tant que</p>
                            <p className="font-semibold">{data.email}</p>
                        </DropdownItem>
                        <DropdownItem key="logout" color="success" as={Link} href={'/profile'}>
                            Profile
                        </DropdownItem>
                        <DropdownItem key="logout" color="danger" as={Link} href={'/feedback'}>
                            Envoyer un feedback
                        </DropdownItem>
                        <DropdownItem key="logout" color="danger" onClick={logOut}>
                            Déconnexion
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </NavbarContent>}

            {!userLoggedIn && <NavbarContent justify="end">
                <NavbarItem className="hidden lg:flex">
                    <Link href="/Signin">Connexion</Link>
                </NavbarItem>
                <NavbarItem className="hidden lg:flex">
                    <Button as={Link} color="warning" href="/register" variant="flat">
                        Créer un compte
                    </Button>
                </NavbarItem>
            </NavbarContent>}
            {/*<NavbarContent>
                <Switch
                    defaultSelected
                    isSelected={darkMode}
                    size="lg"
                    color="secondary"
                    thumbIcon={({className}) =>
                        darkMode ? (
                            <MoonIcon className={className}/>
                        ) : (
                            <SunIcon className={className}/>
                        )
                    }
                    onClick={() => {
                        document.getElementsByTagName('body')[0].classList.toggle('dark');
                        setDarkMode(mode => !mode);
                    }}
                >
                    Dark mode
                </Switch>
            </NavbarContent>*/}
            <NavbarContent className="hidden sm:flex" justify="end">
                <Button as={Link} color="primary" className="font-semibold" variant="bordered" href={newDealHref}>
                    Poster un Deal
                </Button>
            </NavbarContent>
            <NavbarContent className="hidden sm:flex" justify="end">
                <CategoriesNavBar setCategory={setCategory}/>
            </NavbarContent>


            <NavbarMenu className={"p-8"}>
                <NavbarMenuItem key={1}>
                    <Link
                        className="w-full  text-red-500"
                        href="/"
                        size="lg"
                    >
                        Actuellement Hot
                    </Link>
                </NavbarMenuItem>
                <NavbarMenuItem key={2}>
                    <Link
                        className="w-full"
                        href="/new"
                        size="lg"
                    >
                        Nouveaux
                    </Link>
                </NavbarMenuItem>
                <NavbarMenuItem key={3}>
                    <Link
                        className="w-full"
                        href="/hot"
                        size="lg"
                    >
                        Highlights
                    </Link>
                </NavbarMenuItem>
                {!userLoggedIn && <>
                    <NavbarMenuItem>
                        <Button as={Link} href="/Signin">Connexion</Button>
                    </NavbarMenuItem>
                    <NavbarMenuItem>
                        <Button as={Link} color="warning" href="/register" variant="flat">
                            Créer un compte
                        </Button>
                    </NavbarMenuItem>
                </>}
            </NavbarMenu>
        </Navbar>
    );
}
