import React, {useState} from "react";
import {Avatar, Button, Divider, Link, Textarea, User} from "@nextui-org/react";
import Toast from "@/components/Toast";

export default function CommentInput({deal, refetchComments, isReply = false, parentCommentID = null, user}) {

    const [comment, setComment] = useState('');
    async function addComment(comment) {
        const response = await fetch(`/api/deals/${deal.id}/comments`, {
            method: 'POST',
            body: comment,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    }

    const [toast, setToast] = useState({ message: '', type: '', visible: false });

    const showError = (message) => {
        setToast({ message: message, type: 'error', visible: true });
        setTimeout(() => setToast({ ...toast, visible: false }), 3000);
    };

    const showSuccess = (message) => {
        setToast({ message: message, type: 'success', visible: true });
        setTimeout(() => setToast({ ...toast, visible: false }), 3000);
    };

    const handleSubmit = async (e) => {
        const commentData = new FormData();
        commentData.append('content', comment);
        commentData.append('deal_id', deal.id);
        commentData.append('user_id', user.id);
        commentData.append('helpful_count', 0);
        if (isReply) {
            commentData.append('parent_id', parentCommentID);
        }
        try {
            const comment = await addComment(commentData);
            setComment('');
            showSuccess('Commentaire ajouté avec succès.');
            refetchComments();
        } catch (e) {
            showError('Erreur lors de l\'ajout du commentaire. Verifiez les informations et réessayez.');
        }
    }

    return (
        <div className="sm:flex w-full items-center justify-center grid-cols-12 gap-4 p-3">
            <User
                name={user.name}
                description={(
                    <Link href="https://twitter.com/jrgarciadev" size="sm" isExternal>
                        @{user.name}
                    </Link>
                )}
                avatarProps={{
                    src: `https://ui-avatars.com/api/?name=${user.name}`
                }}
            />
            <Textarea
                key={'bordered'}
                variant={'bordered'}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                labelPlacement="outside"
                placeholder="Votre commentaire ici..."
                className="col-span-12 md:col-span-6 mb-6 md:mb-0 p-4"
            />
            <Button radius="full" className="bg-gradient-to-tr from-pink-500 to-yellow-500 text-white shadow-lg"
                    onPress={handleSubmit}
            >
                Ajouter
            </Button>
            {toast.visible && <Toast message={toast.message} type={toast.type} />}
        </div>
    )
}