import React, {useEffect} from 'react';
import {EditorState, convertToRaw, ContentState, convertFromRaw} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {useState} from "react";

// Function to handle image upload
const uploadImageCallBack = (file) => {

    return new Promise(
        (resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', 'https://api.imgur.com/3/image');
            xhr.setRequestHeader('Authorization', 'Client-ID XXXXX');
            const data = new FormData();
            data.append('image', file);
            xhr.send(data);
            xhr.addEventListener('load', () => {
                const response = JSON.parse(xhr.responseText);
                resolve(response);
            });
            xhr.addEventListener('error', () => {
                const error = JSON.parse(xhr.responseText);
                reject(error);
            });
        }
    );
}

// Your component
export default function RichTextEditor({setText, initialValue = null}) {
    // ...
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const handelChange = (newEditorState) => {
        setEditorState(newEditorState);
        setText(convertToRaw(newEditorState.getCurrentContent()));
    }

    useEffect(() => {
        if (initialValue === '') return;
        let newEditorState;
        // Check if description is a string and does not contain 'blocks' property
        if (typeof initialValue === 'string' && !initialValue.includes('blocks')) {
            // Create a ContentState from the description string
            const contentState = ContentState.createFromText(initialValue);
            // Create an EditorState from the ContentState
            newEditorState = EditorState.createWithContent(contentState);
        } else {
            // Parse the raw description into a JavaScript object
            const rawDescription = JSON.parse(initialValue);
            // Convert the raw description into an EditorState
            newEditorState = EditorState.createWithContent(convertFromRaw(rawDescription));
        }
        // Update descriptionEditorState with the new EditorState
        setEditorState(newEditorState);
    }, [initialValue]);

    return (
        // ...
        <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={(e) => handelChange(e)}
        />
        // ...
    );
}