import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, CardHeader, Chip, Divider, Spacer} from "@nextui-org/react";
import TextEditor from "~/components/TextEditor";
import CommentInput from "~/components/DealComponent/CommentInput";
import Comment from "~/components/DealComponent/Comment";
import {useDataQuery} from "@/hooks/useDataQuery";
import {CheckIcon} from "@/components/DealComponent/CheckIcon";
import {EditorState, convertFromRaw, convertToRaw, ContentState} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

export default function DealDetails({deal, user}) {
    const [copyButtonText, setCopyButtonText] = useState(`Coupon: ${deal.coupon}`);
    const [buttonColor, setButtonColor] = useState('default');
    const governoratesList =["Ariana",  "Béja",  "Ben Arous",  "Bizerte",  "Gabès",  "Gafsa",  "Jendouba",  "Kairouan",  "Kasserine",  "Kébili",  "Le Kef",  "Mahdia",  "La Manouba",  "Médenine",  "Monastir",  "Nabeul",  "Sfax",  "Sidi Bouzid",  "Siliana",  "Sousse",  "Tataouine", "Tozeur", "Tunis", "Zaghouan"]

    const handleCopyClick = async () => {
        try {
            await navigator.clipboard.writeText(deal.coupon);
            setCopyButtonText('Copié!');
            setButtonColor('success');
            setTimeout(() => {
                setCopyButtonText(`Coupon: ${deal.coupon}`);
                setButtonColor('default');
            }, 3000);
        } catch (err) {
            setCopyButtonText('Une erreur est survenue!');
            setButtonColor('danger');
            setTimeout(() => {
                setCopyButtonText(`Coupon: ${deal.coupon}`);
                setButtonColor('default');
            }, 3000);
        }
    };
    const { data, isLoading, isError, refetch } = useDataQuery({url: `/api/deals/${deal.id}/comments`});
    const [descriptionEditorState, setDescriptionEditorState] = useState(EditorState.createEmpty());
    useEffect(() => {
        if (deal && deal.description) {
            let newEditorState;
            // Check if description is a string and does not contain 'blocks' property
            if (typeof deal.description === 'string' && !deal.description.includes('blocks')) {
                // Create a ContentState from the description string
                const contentState = ContentState.createFromText(deal.description);
                // Create an EditorState from the ContentState
                newEditorState = EditorState.createWithContent(contentState);
            } else {
                // Parse the raw description into a JavaScript object
                const rawDescription = JSON.parse(deal.description);
                // Convert the raw description into an EditorState
                newEditorState = EditorState.createWithContent(convertFromRaw(rawDescription));
            }
            // Update descriptionEditorState with the new EditorState
            setDescriptionEditorState(newEditorState);
        }
    }, [deal]);

    return (
        <>
            <Card className={"border-none bg-background/60 dark:bg-default-100/50 max-w-[800px] w-full p-4"}>
                <CardHeader className={"text-2xl"}>À propos de ce Deal</CardHeader>
                <CardBody>
                    {deal.coupon && <Button onClick={handleCopyClick} color={buttonColor} variant="bordered">{copyButtonText}</Button>}
                    <Spacer x={6}/>
                    {deal.instore && <span>{deal.instore.split(',').map(id => (
                        <Chip
                            startContent={<CheckIcon size={18} />}
                            variant="faded"
                            color="success"
                            key={id}
                        >
                            #{governoratesList[id]}
                        </Chip>
                    ))}</span>}
                    <Editor
                        editorState={descriptionEditorState}
                        toolbarHidden
                        readOnly
                    />
                </CardBody>
            </Card>
            <Spacer x={6}/>
            <Card className={"border-none bg-background/60 dark:bg-default-100/50 max-w-[800px] w-full"}>
                {user && !user.error && <CommentInput deal={deal} refetchComments={refetch} user={user}/>}
                <TextEditor />

                {isLoading && <span>Loading...</span>}
                {!isLoading && data.map(comment => (
                    <div key={comment.id}>
                        <Divider className="my-4" />
                        <Comment key={comment.id} comment={comment} deal={deal} refetchComments={refetch} user={user}/>
                    </div>
                ))}
            </Card>
        </>
    )
}