// PrivacyPolicy.jsx
import React from 'react';
import {Card, CardHeader, Divider, ScrollShadow, Spacer} from "@nextui-org/react";

export default function PrivacyPolicy () {
    return (
        <div className='flex flex-col items-center justify-center w-full'>
            <Spacer x={6}/>
            <Card isBlurred className="flex border-none dark:bg-default-100/50 max-w-[800px] w-full p-8">
                <CardHeader className={"text-large font-bold justify-center"}>
                    Politique de confidentialité de Tunisiandeals
                </CardHeader>
                <ScrollShadow size={100} className="w-full h-[600px]">
                    <p className={"font-semibold"}>Introduction</p>
                    <p>
                        Cette Politique de confidentialité décrit comment Tunisiandeals collecte, utilise, et protège
                        les informations personnelles que vous nous fournissez en utilisant notre site web.
                    </p>
                    <Spacer x={6}/>
                    <p className={"font-semibold"}>Informations que Nous Collectons</p>
                    <p>Nous pouvons collecter les types d'informations suivants :</p>
                    <ul>
                        <li><div className={"text-medium font-medium text-blue-500"}>Informations personnelles:</div> nom, adresse e-mail, et
                            autres informations similaires.
                        </li>
                        <li><div className={"text-medium font-medium text-blue-500"}>Informations de connexion:</div> nom d'utilisateur, mot de passe, et autres
                            informations nécessaires pour accéder à votre compte.
                        </li>
                        <li><div className={"text-medium font-medium text-blue-500"}>Informations de localisation:</div> données de localisation pour fournir des
                            services basés sur votre emplacement.
                        </li>
                        <li><div className={"text-medium font-medium text-blue-500"}>Contenu utilisateur:</div> photos, commentaires, et autres contenus que vous
                            publiez sur notre site.
                        </li>
                    </ul>

                    <Spacer x={6}/>
                    <p className={"font-semibold"}>Utilisation des Informations</p>
                    <p>Nous utilisons les informations collectées pour :</p>
                    <ul>
                        <li>Fournir et améliorer nos services.</li>
                        <li>Personnaliser votre expérience utilisateur.</li>
                        <li>Communiquer avec vous au sujet de votre compte ou de nos services.</li>
                        <li>Répondre à vos questions et demandes de support.</li>
                        <li>Analyser l'utilisation du site pour améliorer nos services.</li>
                    </ul>

                    <Spacer x={6}/>
                    <p className={"font-semibold"}>Partage des Informations</p>
                    <p>Nous ne vendons ni ne louons vos informations personnelles à des tiers. Nous pouvons partager vos
                        informations avec :</p>
                    <ul>
                        <li>Des prestataires de services tiers qui nous aident à fournir nos services.</li>
                        <li>Les autorités légales si requis par la loi.</li>
                        <li>Dans le cadre d'une fusion, acquisition ou vente d'actifs, vos informations peuvent être
                            transférées à la nouvelle entité.
                        </li>
                    </ul>

                    <Spacer x={6}/>
                    <p className={"font-semibold"}>Sécurité des Informations</p>
                    <p>Nous mettons en œuvre des mesures de sécurité appropriées pour protéger vos informations
                        personnelles contre tout accès non autorisé, altération, divulgation ou destruction.</p>

                    <Spacer x={6}/>
                    <p className={"font-semibold"}>Vos Droits</p>
                    <p>Vous avez le droit de :</p>
                    <ul>
                        <li>Accéder à vos informations personnelles que nous détenons.</li>
                        <li>Demander la correction de vos informations personnelles si elles sont inexactes.</li>
                        <li>Demander la suppression de vos informations personnelles.</li>
                        <li>Vous opposer au traitement de vos informations personnelles.</li>
                    </ul>

                    <Spacer x={6}/>
                    <p className={"font-semibold"}>Modifications de la Politique de Confidentialité</p>
                    <p>Nous pouvons mettre à jour cette Politique de confidentialité de temps en temps. Nous vous
                        informerons de toute modification en publiant la nouvelle politique sur notre site. Les
                        modifications entreront en vigueur dès leur publication.</p>

                    <Spacer x={6}/>
                    <p className={"font-semibold"}>Contact</p>
                    <p>Pour toute question concernant cette Politique de confidentialité, veuillez nous contacter à
                        l'adresse suivante : <a
                            href="mailto:votre-email@tunisiandeals.com">votre-email@tunisiandeals.com</a>.</p>
                    <p className={"font-semibold"}>Contact</p>
                    <p>Pour toute question concernant cette Politique de confidentialité, veuillez nous contacter à l'adresse
                        suivante: <a
                            href="mailto:businessandadministration@it-patrol.tech">businessandadministration@it-patrol.tech</a>.
                    </p>

                </ScrollShadow>
            </Card>
        </div>
    )
}